import axiosInstance from '../../main/axios';
import configLocal from '../../configuracao/configLocal';
export function getPlanoList(contId) {


    const id = parseInt(contId) ? contId : configLocal.getConfig('cont');
    const request = axiosInstance.get(`/planos/planos/controlador/${id}`)

    return {
        type: 'PLANO_FETCHED',
        payload: request
    }
}

export function getPlanoAllList() {

    const request = axiosInstance.get(`/planos/planos/all`)

    return {
        type: 'PLANO_FETCHED',
        payload: request
    }
}

export function getControladorList() {
    const request = axiosInstance.get(`/controladores`)

    return {
        // type: 'CONTROLADOR_FETCHED',
        type: 'CONTROLADORHEADER_FETCHED',
        payload: request
    }
}

export function getControladorGrupoList() {

    const grupoFisicoId = configLocal.getConfig('grupo');

    const request = axiosInstance.get(`/controladores/${grupoFisicoId}`)

    return {
        type: 'CONTROLADOR_FETCHED',
        payload: request
    }
}

export function getControladorByGroupId(grupoFisicoId) {

    const id = parseInt(grupoFisicoId) ? grupoFisicoId : configLocal.getConfig('grupo');
    const request = axiosInstance.get(`/controladores/${id}`)

    return {
        type: 'CONTROLADOR_FETCHED',
        payload: request
    }
}

export function getConfControladorGrupoList() {

    const grupoFisicoId = configLocal.getConfig('grupo');

    const request = axiosInstance.get(`/controladores/conf/${grupoFisicoId}`)

    return {
        type: 'CONTROLADOR_CONF_FETCHED',
        payload: request
    }
}

export function getControladorAnelList() {
    const request = axiosInstance.get(`/controladores/aneis/all`)

    return {
        // type: 'CONTROLADOR_FETCHED',
        type: 'CONTROLADORANEL_FETCHED',
        payload: request
    }
}

export function getGrupoFisicoList() {
    const request = axiosInstance.get(`/gruposfisicos`)

    return {
        type: 'GRUPO_FISICO_FETCHED',
        payload: request
    }
}

export function getGrupoUsuarioList() {
    const request = axiosInstance.get(`/grupos`);

    return {
        type: 'GRUPO_FETCHED',
        payload: request
    }
}

export function getModoOperacaoList() {
    const request = axiosInstance.get(`/planos/modoop`)

    return {
        // tem que criar um reducer para percorrer o objeto e achar o MODOOPERACAO_FETCHED com isso ele pega a propriedade "data"
        type: 'MODOOPERACAO_FETCHED',
        payload: request
    }
}

export function getDetectorList(contId) {

    const request = axiosInstance.get(`/detectores/${contId}`)

    return {
        type: 'DETECTOR_FETCHED',
        payload: request
    }
}


