import React, { Component } from 'react';

class FasesConflitantes extends Component {

    constructor(props) {
        super(props);
        this.geralFasesConflito = this.geralFasesConflito.bind(this);
    }

    geralFasesConflito() {
        let faseGrupoObj = [];
        let fasesIni = parseInt(this.props.faseInicial)+1;
        let fasesFin = this.props.faseFinal;

        for (let idxFase = fasesIni; idxFase <= fasesFin; idxFase++) {
            let faseObj = [];
            for (let idx = fasesIni; idx <= idxFase; idx++) {
                let sName = `fase${idx - 1}_fase${idxFase}`;
                faseObj.push(
                    <label>
                        <input name={sName} key={`fase${idx - 1}_fase${idxFase}`}  type="checkbox" checked={this.props.info_fases_conflitantes[0][sName]}></input>
                        {`F${idx - 1} c/ F${idxFase}`}
                    </label>
                );
            }

            faseGrupoObj.push(
                <div key={`fase${idxFase}`}>
                    {faseObj}
                </div>
            );
        }

        return faseGrupoObj;
    }

    render() {
        return (
            <div>
                <div>
                    <h4>Fases não conflitantes: </h4>
                </div>
                {this.geralFasesConflito()}
            </div>
        )
    }
}

export default FasesConflitantes;