import axios from 'axios';
import consts from '../consts';
const BASE_URL = consts.OAPI_URL;
const domains = window.location.hostname.split('.');

const axiosPublicApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'X-Subdomain': domains[0] === 'www' ? domains[1]: domains[0],
  },
});

export default axiosPublicApi;
