import React, { Component } from 'react';
import Grid from '../common/layout/grid';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Button from '../common/form/button';
import { testarConexao, reset, enviarRelogioControlador } from './controladorActions';
import { toastr } from 'react-redux-toastr';
import ReactLoading from 'react-loading';
import { Modal } from 'react-bootstrap';
import PermissaoUsuario from '../auth/permissao';
import { Field } from 'redux-form';
import labelAndInput from '../common/form/labelAndInput';
import swal from 'sweetalert2';

// import { StatusCon } from './status';

class ControladorConexao extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    getConexao(enderecoUDP) {

        window.$('#dataHoraControlador').val('Conectando...');

        this.props.testarConexao(enderecoUDP, this.props.numCont)
            .then((result) => {

                window.$('#dataHoraControlador').val(result.payload.data.data + '  ' + result.payload.data.hora);

                window.$('#btnTestarConexao').removeClass('btn-default');
                window.$('#btnTestarConexao').removeClass('btn-warning');
                window.$('#btnTestarConexao').addClass('btn-success');
                window.$('#statusConexaoCont').html('<b>Status:</b> Online');

                // dispatch([
                //     { type: 'CONEXAO_CONTROLE', dataHora: resp.data, conectado: true }
                // ])
            })
            .catch((e) => {
                window.$('#dataHoraControlador').val('Sem conexão');
                window.$('#statusConexaoCont').html('<b>Status:</b> Offline');

                window.$('#btnTestarConexao').removeClass('btn-default');
                window.$('#btnTestarConexao').removeClass('btn-success');
                window.$('#btnTestarConexao').addClass('btn-warning');
                toastr.error('Erro', 'Sem conexão. O número ou endereço do controlador podem estar errados.');
                // e.response.data.errors.forEach(
                //     error => toastr.error('Erro', error))
            })

    }

    reset(enderecoUDP) {
        swal({
            title: '<h3 style="color:red">Atenção</h3>',
            html: '<h4>Deseja resetar o controlador?</h4>',
            type: 'alert',
            width: '520px',
            heightAuto: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
            cancelButtonColor: '#f39c12',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
            timer: 30000,
        }).then((response) => {
            if (response.value) {
                this.setState({ show: true });
                this.props.reset(enderecoUDP, this.props.numCont)
                    .then((result) => {
                        this.setState({ show: false });
                        toastr.success('Sucesso!', result.payload.data);
                    })
                    .catch((e) => {
                        this.setState({ show: false });
                        toastr.error('Erro', 'Sem conexão. O número ou endereço do controlador podem estar errados.');
                    });
            }
        });
    }

    enviarRelogio(enderecoUDP) {
        this.setState({ show: true });

        this.props.enviarRelogioControlador(enderecoUDP, this.props.numCont)
            .then((result) => {
                this.setState({ show: false });

                if (result.error) {
                    toastr.error('Erro', result.payload.response.data.errors[0]);
                } else {
                    toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                }
            })
            .catch(e => {
                this.setState({ show: false });
                toastr.error('Erro', e);
            });
    }

    render() {
        const enderecoUDP = this.props.udp || [];

        return (
            <>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName='modal-load'
                    backdrop={false} >
                    <Modal.Body className='modal-body modal-load-body'>
                        <div >
                            <div >
                                <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                            </div>
                            <div >
                                <h4><b>Aguarde, processando...</b></h4>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                <Field
                    name='ip'
                    component={labelAndInput}
                    ref='controladorip'
                    label='IP do Controlador*'
                    cols='12 4'
                    placeholder='Informe o IP'
                    validate={this.props.required}
                    readOnly={this.props.readOnly('CadControlador', 'ip_porta_controlador')}
                />

                <Field
                    name='porta'
                    component={labelAndInput}
                    label='Porta do Controlador*'
                    cols='12 4'
                    placeholder='Informe a porta'
                    validate={this.props.required}
                    readOnly={this.props.readOnly('CadControlador', 'ip_porta_controlador')}
                    />

                <div className="col-xs-12 col-sm-2">
                    <div className="form-group form-group-sm">
                        <label className="label-gt" id='statusConexaoCont'>Status:</label>

                        <PermissaoUsuario tela="CadControlador" campo="testar_conexao" condicao={true}>
                            <Button type='button' id='btnTestarConexao' className='primary form-control' icon='plug' label='Testar Conexão' onClick={() => this.getConexao(enderecoUDP)} />
                        </PermissaoUsuario>

                        <PermissaoUsuario tela="CadControlador" campo="testar_conexao" condicao={false}>
                            <Button type='button' id='btnTestarConexao' className='primary form-control' icon='plug' label='Testar Conexão' disabled="disabled" />
                        </PermissaoUsuario>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-2">
                    <div className="form-group form-group-sm">
                        <div><b>&nbsp;</b></div>
                        <PermissaoUsuario tela="CadControlador" campo="resetar_controlador" condicao={true}>
                            <Button type='button' id='btnResetar' className='danger form-control' icon='power-off' label='Resetar' onClick={() => this.reset(enderecoUDP)} />
                        </PermissaoUsuario>

                        <PermissaoUsuario tela="CadControlador" campo="resetar_controlador" condicao={false}>
                            <Button type='button' id='btnResetar' className='danger form-control' icon='power-off' label='Resetar' disabled="disabled" />
                        </PermissaoUsuario>
                    </div>
                </div>

                <Field
                    name='dataHoraControlador'
                    id='dataHoraControlador'
                    component={labelAndInput}
                    label='Data/Hora'
                    cols='12 9'
                    readOnly={true}
                />

                <div className="col-xs-12 col-sm-3">
                    <div className="form-group form-group-sm">
                        <div><b>&nbsp;</b></div>
                        <PermissaoUsuario tela="CadControlador" campo="enviar_relogio" condicao={true}>
                            <Button type='button' id='btnEnviarRelogioControlador' className='primary form-control' icon='arrow-up' label='Enviar Data/Hora' onClick={() => this.enviarRelogio(enderecoUDP)} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadControlador" campo="enviar_relogio" condicao={false}>
                            <Button type='button' id='btnEnviarRelogioControlador' className='primary form-control' icon='arrow-up' label='Enviar Data/Hora' disabled="disabled" />
                        </PermissaoUsuario>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ testarConexao, reset, enviarRelogioControlador }, dispatch)
export default connect(null, mapDispatchToProps)(ControladorConexao)

//export default ControladorConexao;