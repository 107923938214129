import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getGrupoFisicoList } from './selectdbAtions';
import { getConfig, setConfig } from '../../configuracao/configLocal';
import { getConfControladorGrupoList } from './selectdbAtions';

import "./contentHeaderFilter.css";

class SelectConfGrupoFisico extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: getConfig('grupo')
        }

    }

    componentWillMount() {
        this.props.getGrupoFisicoList();
    }

    renderOptions() {
        const list = this.props.list || [];

        if (list.length === 0) {
            return <option value=''>Carregando...</option>
        }
        
        return list.map(grupo => (
            <option key={grupo.id} value={grupo.id}>
                {grupo.id} - {grupo.descricao}
            </option>
        ))
    }

    handleChange(event) {
        this.setState({value: event.target.value });
        setConfig('grupo', event.target.value);
        setConfig('cont', 0);
        setConfig('numCont', 0);
        setConfig('anel', 0);
        this.props.getConfControladorGrupoList();
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    render () {
        return (
            <div className='contentHeaderFilter'>
                <label style={{ 'display': 'inline' }} htmlFor={'selectGrupoFisico'}><small>Grupo Físico: </small></label>
                <select
                    id={'selectGrupoFisico'}
                    name={'selectGrupoFisico'}
                    style={{'width': '150px', 'display': 'inline'}}
                    class={'form-control'}
                    onChange={event=>{this.handleChange(event)}}
                    value={this.state.value}>
                    <option value=''>{this.props.emptyText || 'Selecione...'}</option>
                    {this.renderOptions()}
                </select>
            </div>
        )
    }
}

const mapStateToProps = state => ({ list: state.grupoFisicoCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getGrupoFisicoList, getConfControladorGrupoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectConfGrupoFisico)
