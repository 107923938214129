import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getConfig } from '../../configuracao/configLocal';
import { getControladorAnelList } from './selectdbAtions';

import "./contentHeaderFilter.css";

class SelectConfAnel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        }
    }

    componentWillMount() {
        this.props.getControladorAnelList();
    }

    renderOptions() {
        const list = this.props.list || [];

        if (list.length === 0) {
            return <option value=''>Carregando...</option>
        }
        const newList = list.filter(anel => anel.grupo_fisico_id == getConfig('grupo') && anel.numero_controlador == getConfig('numCont') && anel.ativo == 1);
        
        return newList.map(anel => (
            <option id={anel.id} value={anel.anel}>{anel.anel}</option>
        ))
    }

    handleChange(event) {
        this.setState({value: event.target.value });
        console.log('handleChange', event.target.value);
        console.log('handleChange', this.state.value);
        this.props.onChange(event);
    }

    render () {
        return (
            <div className="btn-group" role="group">
                <label htmlFor={'selectAnel'}>Anel:</label>
                <select
                    id={'selectAnel'}
                    name={'selectAnel'}
                    style={{'width': '150px', 'display': 'block', marginBottom:'5px'}}
                    class={'form-control'}
                    onChange={event=>{this.handleChange(event)}}
                    value={this.state.value}>
                    {this.renderOptions()}
                </select>
            </div>
        )
    }
}

const mapStateToProps = state => ({ list: state.controladorCad.listAnel })
const mapDispatchToProps = dispatch => bindActionCreators({ getControladorAnelList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectConfAnel)