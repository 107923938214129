import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import { getConfig, setConfig } from '../configuracao/configLocal';

let INITIAL_VALUES = {
    ativo: 1,
    controlador_mestre_id: 0,
    numero_controlador: 0,
    anel: 1, // sempre seleciona no cbx o anel 1

    // AQUI REPETE TUDO QUE TEM NO ARRAY[0] DE ANEIS
    tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3,
    numero_fases: 0,
    numero_fases_fim: 0,// para gerar o grid em branco
    nobreak: 0,
    // fases: [],
    aneis: [// cadastra com 4 aneis porém só o anel 1 fica ativo
        { anel: 1, endereco: '', cruzamento: '', numero_fases: 0, numero_fases_fim: 0, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 1, fases: [], imageLog:'in' },
        { anel: 2, endereco: '', cruzamento: '', numero_fases: 0, numero_fases_fim: 0, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 0, fases: [], imageLog:'in' },
        { anel: 3, endereco: '', cruzamento: '', numero_fases: 0, numero_fases_fim: 0, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 0, fases: [], imageLog:'in' },
        { anel: 4, endereco: '', cruzamento: '', numero_fases: 0, numero_fases_fim: 0, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 0, fases: [], imageLog:'in' },
    ]
}

export function getList() {
    const request = axiosInstance.get(`/controladores/${getConfig('grupo')}`)
    return {
        type: 'CONTROLADOR_FETCHED',
        payload: request
    }
}

export async function getFaseList(contid, faseIni, faseFin) {
    const request = await axiosInstance.get(`/fases/${contid}/${faseIni}/${faseFin}`);
    return {
        type: 'FASE_FETCHED',
        payload: request
    }
}

export function getFaseListNovo(faseIni, faseFin) {
    const request = axiosInstance.get(`/fases/controlador/novo/${faseIni}/${faseFin}`);
    return {
        type: 'FASE_FETCHED',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete');
}

function submit(values, method) {
    let cont_id = parseInt(values.id);
    let conf_id = parseInt(getConfig('cont'));

    values['grupo_fisico_id'] = getConfig('grupo');

    return dispatch => {
        const id = values.id ? values.id : ''

        let URL = `/controladores/${id}`;
        if (method === 'delete'){// se for delete tem que passar o grupo e o número do controlador pois tem que apagar os 4 aneis
            URL = `/controladores/${values.grupo_fisico_id}/${values.numero_controlador}`;
            values = {data: values }; // se for um delete tem que adicionar como data para aparecer no body do backend e gravar o log
        }

        axiosInstance[method](URL, values)
            .then(resp => {
                // if (method === 'post') {
                    //     dispatch(showFase({id :resp.data[0]}));
                // } else {
                //     dispatch(init());
                // }
                // if (cont_id === conf_id) {
                    if (method === 'delete' && cont_id === conf_id) {
                        setConfig('cont', 0);
                        setConfig('numCont', 0);
                        // _conf = {
                        //     cont: values.id,
                        //     contDes: 'Selecionar Controlador',
                        //     numCont: null,
                        //     contLat: null,
                        //     contLong: null,
                        //     event: 1,
                        //     grupo: _conf.grupo,
                        //     grupoDes: _conf.grupoDes
                        // }
                        // // localStorage.removeItem('_cfg');
                        // window.$('#selControladorNavBar span').text('Selecionar Controlador');
                    } else {
                        setConfig('cont', values.id);
                        setConfig('numCont', values.numero_controlador);
                        // _conf = {
                        //     cont: values.id,
                        //     contDes: `${values.numero_controlador} - ${values.endereco}`,
                        //     numCont: null,
                        //     contLat: null,
                        //     contLong: null,
                        //     event: 2,
                        //     grupo: _conf.grupo,
                        //     grupoDes: _conf.grupoDes
                        // }

                        // window.$('#selControladorNavBar span').text(values.endereco);
                    }

                    // localStorage.setItem('_cfg', JSON.stringify(_conf));
                    // window.$('#selControladorNavBar')[0].click();
                // } else {
                //     window.$('#selControladorNavBar')[0].click();
                // }
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                dispatch(init());
            })
            .catch(e => {
                toastr.error('Erro', e);
            })
    }
}

// export function showFase(controlador) {

//     return [
//         initialize('controladorFaseList', controlador),
//         showTabs('tabFase'),
//         selectTab('tabFase')
//     ]
// }

export function showSAP(controlador) {
    return [
        showTabs('tabSAP'),
        selectTab('tabSAP'),
        initialize('controladorSAP', controlador)
    ]
}

export function showUpdate(controlador) {
    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('controladorForm', controlador)
    ]
}

export function getImageCroquiById(id) {
    try {
        axiosInstance.get(`/controladores/download/${id}`, {
            responseType: 'text' // Indicando que o tipo de resposta é texto
        }).then(response => {
            if (response.data) {
                console.log(response.data);
                const pdfData = Buffer.from(response.data, 'hex');
                const blob = new Blob([pdfData], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                return response;
            } else {
                console.error('Erro ao baixar o arquivo');
            }
        }).catch(error => {
            console.error('Erro ao baixar o arquivo:', error);
        });
    } catch (error) {
        console.error('Erro ao baixar o arquivo:', error);
    }
}

export function downloadCroqui(croqui_file_id) {
    try {
        axiosInstance.get(`/controladores/download/${croqui_file_id}`, {
            responseType: 'text' // Indicando que o tipo de resposta é texto
        }).then(response => {
            if (response.data) {
                const pdfData = Buffer.from(response.data, 'hex');
                const blob = new Blob([pdfData], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${croqui_file_id}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('Erro ao baixar o arquivo');
            }
        }).catch(error => {
            console.error('Erro ao baixar o arquivo:', error);
        });
    } catch (error) {
        console.error('Erro ao baixar o arquivo:', error);
    }
}
export function showDelete(controlador) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('controladorForm', controlador)
    ]
}

export function init() {
    INITIAL_VALUES = {
        ativo: 1,
        controlador_mestre_id: 0,
        numero_controlador: 0,
        anel: 1, // sempre seleciona no cbx o anel 1

        // AQUI REPETE TUDO QUE TEM NO ARRAY[0] DE ANEIS
        tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3,
        numero_fases: 0,
        numero_fases_fim: 0,// para gerar o grid em branco
        nobreak: 0,
        // fases: [],
        aneis: [// cadastra com 4 aneis porém só o anel 1 fica ativo
            { anel: 1, endereco: '', cruzamento: '', numero_fases: 0, numero_fases_fim: 0, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 1, fases: [] },
            { anel: 2, endereco: '', cruzamento: '', numero_fases: 0, numero_fases_fim: 0, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 0, fases: [] },
            { anel: 3, endereco: '', cruzamento: '', numero_fases: 0, numero_fases_fim: 0, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 0, fases: [] },
            { anel: 4, endereco: '', cruzamento: '', numero_fases: 0, numero_fases_fim: 0, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 0, fases: [] },
        ]
    }
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        initialize('controladorForm', INITIAL_VALUES)
    ]
}

export function testarConexao(enderecoUDP, numCont) {
    const ip = enderecoUDP.ip ? enderecoUDP.ip : '';
    const porta = enderecoUDP.porta ? enderecoUDP.porta : '';
    const request = axiosInstance.get(`/controladores/cxudp/${ip}/${porta}/${numCont}`);

    return {
        type: 'CONEXAO_CONTROLE',
        payload: request
    }
}

export function reset(enderecoUDP, numCont) {
    const ip = enderecoUDP.ip ? enderecoUDP.ip : '';
    const porta = enderecoUDP.porta ? enderecoUDP.porta : '';
    const request = axiosInstance.get(`/controladores/reset/${ip}/${porta}/${numCont}`);

    return {
        type: '',
        payload: request
    }
}

export function enviarRelogioControlador(enderecoUDP, numCont) {
    let ip = "0";
    let porta = 0;

    if (enderecoUDP) {
        ip = enderecoUDP.ip ? enderecoUDP.ip : '';
        porta = enderecoUDP.porta ? enderecoUDP.porta : '';
    };

    const request = axiosInstance.get(`/controladores/enviarelogio/${ip}/${porta}/${numCont}`);

    return {
        type: '',
        payload: request
    }
}

export function alteraMonitora(numero, value) {
    let values = {
        grupo: getConfig('grupo'),
        numero_controlador: numero,
        monitora_erro: value
    };

    const request = axiosInstance.put(`/controladores/monitora`, values);

    return {
        type: '',
        payload: request
    }
}

export function enviarHorarioVeraoControlador(values) {
    const request = axiosInstance.put(`/controladores/horarioverao/enviar`, values);

    return {
        type: '',
        payload: request
    }
}

export function enviarTemposControlador(values) {
    const request = axiosInstance.put(`/controladores/temposeguranca/enviar`, values);

    return {
        type: '',
        payload: request
    }
}

export function lerTemposControlador(enderecoUDP, numCont, anel) {
    let ip = "0";
    let porta = 0;

    if (enderecoUDP) {
        ip = enderecoUDP.ip ? enderecoUDP.ip : '';
        porta = enderecoUDP.porta ? enderecoUDP.porta : '';
    };

    const request = axiosInstance.get(`/controladores/temposeguranca/${ip}/${porta}/${numCont}/${anel}`);

    return {
        type: '',
        payload: request
    }
}

export function lerAneis(numero, grupo) {
    const request = axiosInstance.get(`/controladores/numero/${numero}/${grupo}`);

    return {
        type: 'CONTROLADORANEIS_FETCHED',
        payload: request
    }
}

export function lerQuantidadeAneis(enderecoUDP, numCont, anel) {
    let ip = "0";
    let porta = 0;

    if (enderecoUDP) {
        ip = enderecoUDP.ip ? enderecoUDP.ip : '';
        porta = enderecoUDP.porta ? enderecoUDP.porta : '';
    };

    const request = axiosInstance.get(`/controladores/quantidadeaneis/${ip}/${porta}/${numCont}/${anel}`);

    return {
        type: '',
        payload: request
    }
}

export function lerDadosFasesControlador(enderecoUDP, numCont, anel) {
    let ip = "0";
    let porta = 0;

    if (enderecoUDP) {
        ip = enderecoUDP.ip ? enderecoUDP.ip : '';
        porta = enderecoUDP.porta ? enderecoUDP.porta : '';
    };

    const request = axiosInstance.get(`/controladores/dadosfases/${ip}/${porta}/${numCont}/${anel}`);

    return {
        type: '',
        payload: request
    }
}

export function getLocalCEP(cep) {
    const request = axiosInstance.get(`/controladormap/${cep}`);

    return {
        type: '',
        payload: request
    }
}
