import React, { Component } from 'react';
import "./contentHeaderFilter.css";

class SelectConfigTraffic extends Component {
    render () {
        return (
            <div className='contentHeaderFilter'>
                <label htmlFor="displayTraffic" >
                    <small>Mostrar tráfego</small>
                </label>
                <input
                    type="checkbox"
                    id="displayTraffic"
                    name="displayTraffic"
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    defaultChecked={true}
                    style={{
                        'marginLeft': '5px'
                    }}
                />
            </div>
        )
    }
}

export default SelectConfigTraffic;