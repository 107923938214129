import React from 'react';

import HtmlReport from './HtmlReport';
import Header from '../../common/relatorio/header';
import Footer from '../../common/relatorio/footer';

import {
  informacoesPlano, trocaPlanos, infoFasesConflitantes
} from '../programacoes_plano/generateReportActions'; 

import UserLocal from '../../configuracao/userLocal'

import {getFaseList} from '../../controlador/controladorActions';

import ReactDOMServer from 'react-dom/server';

function HtmlContent(planos, troca_planos, fases, info_fases_conflitantes) {
  return ReactDOMServer.renderToString(<HtmlReport planos={planos} troca_planos={troca_planos} fases={fases} info_fases_conflitantes={info_fases_conflitantes}/>);
};

function FooterReport(name) {
  return ReactDOMServer.renderToString(<Footer name={name}/>);
};


function HeaderReport() {
  const title = 'Programações de Plano';
  return ReactDOMServer.renderToString(<Header title={title}/>);
};

export const generateReport = async (iNumeroControlador, iGrupoFisico, iPlano) => {
  const aInfoPlano = await informacoesPlano(iNumeroControlador, iGrupoFisico, iPlano);
        
  const iFaseInicial = aInfoPlano.data[0][0].faseIni;
  const iFaseFinal = aInfoPlano.data[0][0].faseFin;

  const aFases = await getFaseList(iNumeroControlador, iFaseInicial, iFaseFinal);

  const aTrocaPlanos =  await trocaPlanos(iNumeroControlador, iPlano);

  const aInfoFasesConflitantes = await infoFasesConflitantes(iNumeroControlador, iGrupoFisico);

  const htmlReport = HtmlContent(aInfoPlano.data, aTrocaPlanos.data, aFases.payload.data, aInfoFasesConflitantes.data);
  
  const htmlFooter = FooterReport(UserLocal.getUserConfig('name'));
  const htmlHeader = HeaderReport();

  return `
      <html>
          <head>
              <style>
              .container-planos {
                  margin-top: 60px; /* Espaço para o cabeçalho */
                  margin-bottom: 40px; /* Espaço para o rodapé */
                  padding: 20px;
              }
      
              .planos-other-div {
                  margin-top: 60px; /* Espaço para o cabeçalho */
                  margin-bottom: 40px; /* Espaço para o rodapé */
                  padding: 20px;
              }   
      
              .container-content {
                  margin-top: 60px; /* Espaço para o cabeçalho */
                  margin-bottom: 40px; /* Espaço para o rodapé */
                  padding: 20px;
              }
      
              .tabela-troca-plano {
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
              </style>
          </head>
          ${htmlHeader}
          
              ${htmlReport}
          
          ${htmlFooter}
      </html>
  `;
}