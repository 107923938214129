import React from 'react';
import moment from 'moment';

const Footer = ({ name }) => {
  return (
    <footer style={{ 
      fontSize: '10px', 
      width: '100%', 
      padding: '10px', 
      textAlign: 'center', 
      backgroundColor: 'darkgray', 
      WebkitPrintColorAdjust: 'exact', 
      position: 'fixed',
      bottom: '0', 
      zIndex: '1000'}}>
        
      <div style={{ float: 'left' }}>{moment().format('DD/MM/YYYY [às] HH:mm - ')} {name}</div>
    </footer>
  );
};

export default Footer;