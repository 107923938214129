import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';

const INITIAL_VALUES = {
    ativo: 1,
    controlador_mestre_id: 0,
    numero_controlador: 0,
    anel: 1, // sempre seleciona no cbx o anel 1

    // AQUI REPETE TUDO QUE TEM NO ARRAY[0] DE ANEIS
    tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3,
    numero_fases: 1,
    numero_fases_fim: 2,// para gerar o grid em branco
    // fases: [],
    aneis: [// cadastra com 4 aneis porém só o anel 1 fica ativo
        { anel: 1, endereco: '', cruzamento: '', numero_fases: 1, numero_fases_fim: 2, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 1, fases: [] },
        { anel: 2, endereco: '', cruzamento: '', numero_fases: 1, numero_fases_fim: 2, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 0, fases: [] },
        { anel: 3, endereco: '', cruzamento: '', numero_fases: 1, numero_fases_fim: 2, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 0, fases: [] },
        { anel: 4, endereco: '', cruzamento: '', numero_fases: 1, numero_fases_fim: 2, tempo_vermelho_min: 1, tempo_amarelo_min: 3, tempo_verde_min: 3, ativo: 0, fases: [] },
    ]
}

export function getList(contid) {

    const request = axiosInstance.get(`/sap/${contid}`)

    return {
        type: 'SELECAOAUTOMATICA_FETCHED',
        payload: request
    }
}
export function update(values) {
    return submit(values, 'put')
}

export function updateSelecaoAutomaticaControlador(values) {

    let contId = configLocal.getConfig('cont');

    const request = axiosInstance.put(`/sap/selecaoautomatica/${contId}`, values);

    return {
        type: '',
        payload: request
    }
}

function submit(values, method) {

    return dispatch => {
        axiosInstance[method](`/sap`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                //dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function updateControlador(values) {

    return dispatch => {
        // const id = values.id ? values.id : ''
        // const controlador_id = values.controlador_id;

        axiosInstance.put(`/sap/controlador`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                //dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function init() {
    return [
        getList()
    ]
}

export function initControlador() {
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        initialize('controladorForm', INITIAL_VALUES)
    ]
}
