import React from 'react';
import MenuItem from './menuItem';
import MenuTree from './menuTree';
import MenuItemClick from './menuItemClick';

export default props => (
    <ul className='sidebar-menu tree' data-widget='tree'>
        <MenuItem path='/' label='Dashboard' icon='tachometer-alt' />
        {/* <MenuTree label='Cadastro' icon='edit'> */}

        <MenuTree label='Cadastro' icon='edit'>
            <MenuItem path='cadgrupo'
                label='Grupo de Usuário' icon='group' />

            <MenuItem path='cadusuario'
                label='Usuário' icon='user' />

            {/* <MenuItem path='cadtipoalarme'
                label='Tipos de Alarme' icon='bell' /> */}

            <MenuItem path='cadgrupofisico'
                label='Grupo Físico' icon='server' />

            <MenuItem path='cadcontrolador'
                label='Controlador' icon='hdd' />

            {/* <MenuItem path=''
                label='Cadastro de Mapa' icon='map-marked-alt' /> */}

        </MenuTree>

        <MenuTree label='Controlador' icon='hdd'>

            {/* <MenuItem path='caddetector'
                label='Cadastro de Detector' icon='video' /> */}

            {/* <MenuItem path='cadfase'
                label='Fases do Controlador' icon='traffic-light' /> */}

            <MenuItem path='cadplano'
                label='Planos do Controlador' icon='sitemap' />

            <MenuItem path='cadtrocaplano'
                label='Tabela de Troca de Plano' icon='table' />

            <MenuItem path='caddataespecial'
                label='Datas Especiais' icon='calendar-alt' />

            {/* <MenuItem path='cadadaptativo'
                label='Adaptativo Tempo Real' icon='clock' /> */}

            <MenuItem path='contagemvolumetrica'
                label='Contagem Volumétrica' icon='car' />

            <MenuItem path='execplanoforcado'
                label='Plano Forçado' icon='gavel' />

            <MenuItem path='concontroladorerro'
                label='Log de Erros' icon='exclamation-triangle' />

            {/* <MenuItem path='cadintervaloplano'
                label='Intervalos Plano' icon='sitemap' /> */}
        </MenuTree>

        <MenuItem path='mapadefases' label='Mapa de Fases' icon='map-marked-alt' />

        <MenuItem path='concontroladorerrogeral'
            label='Controladores com Erro' icon='exclamation-triangle' />

        <MenuItem path='conlogusuario'
            label='Log de Alteração' icon='history' />

        <MenuTree label='Câmeras' icon='camera'>
            <MenuItemClick
                path='/'
                name='cameradeteccao'
                label='Câmera de Detecção'
                icon='video'
                onClick={(e, menu) => {
                    window.open('http://192.168.1.112/');
                    window.open('http://192.168.1.114/');
                    window.open('http://192.168.1.116/');
                    window.open('http://192.168.1.118/');
                }}
            />
            <MenuItemClick path='/' name='cameradeteccao' label='Câmera Monitoramento' icon='video' onClick={(e, menu) => { window.open('http://192.168.1.77/'); }} />
        </MenuTree>

        <MenuTree label='Notificações' icon='exclamation'>
            <MenuItem path='telegram'
                label='Telegram' icon='mobile' />
        </MenuTree>

        {/* Comentar menu */}
        {/* <MenuTree label='Relatórios' icon='file-pdf'> 
            <MenuItem path='relatorio'
                label='Programações de Planos' icon='file-pdf' />
        </MenuTree> */}
    </ul>
)