import axios from 'axios';
import consts from '../consts';
const BASE_URL = consts.API_URL;
const domains = window.location.hostname.split('.');

const axiosPrivateApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'X-Subdomain': domains[0] === 'www' ? domains[1]: domains[0],
  },
});

export default axiosPrivateApi;
