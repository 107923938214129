import axiosInstance from '../../main/axios';

export async function informacoesPlano(iNumeroControlador, iGrupoFisico, iPlano) {
    const aResult = await axiosInstance.get(`/programacoes_planos/${iNumeroControlador}/${iGrupoFisico}/${iPlano}`);
    return aResult;
}

export async function trocaPlanos(iNumeroControlador, iPlano) {
    const aResult = axiosInstance.get(`/trocaplanos_report_programacao_planos/${iNumeroControlador}/${iPlano}`);
    return aResult;
}

export async function infoFasesConflitantes(iNumeroControlador, iGrupoFisico) {
    const aResult = await axiosInstance.get(`/controladores/${iGrupoFisico}/${iNumeroControlador}`);
    return aResult;
}