import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LabelAndSelectChange from '../form/labelAndSelectChange';

import { getControladorAnelList } from './selectdbAtions';

class SelectAnelControlador extends Component {

    constructor(props) {
        super(props);

        this.props.getControladorAnelList();

        this.state = {
            value: this.props.value
        }
    }

    renderOptions() {
        const list = this.props.list || [];
        var listCont = [];

        if (list.length === 0) {
            return <option value=''>Carregando...</option>
        }

        list.forEach(cont => {
            if ((this.props['data-grupofisico'] == cont.grupo_fisico_id) && (this.props['data-num-controlador'] == cont.numero_controlador) && (cont.ativo == 1)) {
                listCont.push(cont);
            }
        });

        return listCont.map(contro => (// o value vai ser o id do controlador pois ele é utilizado nos filtros do banco
            <option key={contro.id} value={contro.id} data-anel={contro.anel} >{`${contro.anel}`} </option>
        ))
    }

    render() {

        return (

            <LabelAndSelectChange
                id={this.props.id}
                name={this.props.name}
                label={this.props.label}
                cols={this.props.cols}
                onChange={this.props.onChange}
                // onChange={(event)=>{
                //     this.setState({value: event.target.value });
                // }}
                // value={this.state.value}
                value={this.props.value}
            >
                <option value="Selecionar">Selecionar</option>
                {this.renderOptions()}
            </LabelAndSelectChange>

        )
    }
}

const mapStateToProps = state => ({ list: state.controladorCad.listAnel })
const mapDispatchToProps = dispatch => bindActionCreators({ getControladorAnelList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectAnelControlador)