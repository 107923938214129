import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr';
import { reset as initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import configLocal from '../configuracao/configLocal';

const INITIAL_VALUES = () => ({
    controlador_id: 0,
    status: 1,
    numero_intervalo: 4,
    modo_operacao_id: 0,
    imageLog: ''
})

export function getList(contid, planoid, anel) {
    const request = axiosInstance.get(`/intervalos/${contid}/${planoid}/${anel}`)

    return {
        type: 'INTERVALO_FETCHED',
        payload: request
    }
}

export function getListInterval(planoid, anel, numCont, grupo) {
    const com = 38;
    const request = axiosInstance.get(`/intervalos/remote/${com}/${planoid}/${numCont}/${anel}/${grupo}`);

    return {
        type: 'INTERVALO_FETCHED',
        payload: request
    }
}

export function update(values) {
    return submit(values, 'put')
}

function submit(values, method) {

    return async dispatch => {
        await axiosInstance[method](`/intervalos`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function updatePlano(values) {
    const contId = values.controlador_id;
    const request = axiosInstance.put(`/intervalosPlano/${contId}`, values);

    return {
        type: '',
        payload: request
    }

}

export function updateSerial(values, contId, numCont) {
    const request = axiosInstance.put(`/intervalos/remote/${contId}/${numCont}`, values);

    return {
        type: '',
        payload: request
    }

}

export function updatePlanoIntermitente(ativar) {
    let contId = configLocal.getConfig('cont');
    const request = axiosInstance.put(`/intervalos/planointermitente/${contId}/${ativar}`);

    return {
        type: '',
        payload: request
    }
}

export function init() {
    return [
        getList()
    ]
}

export function getListPlano() {
    var numCont = configLocal.getConfig('numCont');
    var grupo = configLocal.getConfig('grupo');
    const request = axiosInstance.get(`/planos/controlador/numero/${numCont}/${grupo}`)

    return {
        type: 'PLANOCONTROLADOR_FETCHED',
        payload: request
    }
}

export function getControllerStep(numCont, anel, grupo) {
    const request = axiosInstance.get(`/fasesgeral/mapa/${numCont}/${anel}/${grupo}`);

    return {
        type: ``,
        payload: request
    }
}

export function initPlano() {

    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getListPlano(),
        initialize('planoForm', INITIAL_VALUES())
    ]
}
