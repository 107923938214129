import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import consts from '../consts';
import { getConfig, setConfig } from '../configuracao/configLocal';
import html2canvas from 'html2canvas';

import LabelAndInputChange from '../common/form/labelAndInputChange';
import {
    getList,
    update,
    initPlano,
    getListInterval,
    updateSerial,
    updatePlano,
    getControllerStep,
} from './intervaloActions';

import {
    generateReport
} from './../relatorio/programacoes_plano/generateReport';

import Select from '../common/form/selectSemGrid';
import { toastr } from 'react-redux-toastr';

import ReactTableGT2A from '../common/grid/gridReactTable';
import Button from '../common/form/button';
import ButtonTooltip from '../common/form/buttonTooltip';

import traffic from '../image/traffic.png';
import trafficBranco from '../image/traffic_branco.png';
import trafficVermelho from '../image/traffic_red.png';
import trafficVerde from '../image/traffic_green.png';
import trafficAmarelo from '../image/traffic_yellow.png';
import If from '../common/operator/if';
import { Modal, Row } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import swal from 'sweetalert2';
import SelectPlanoSelect from '../common/selectdb/selectPlanoSelect';
import SelectConfAnel from '../common/selectdb/selectConfAnel.jsx';

import Grid from '../common/layout/grid';

import PermissaoUsuario from '../auth/permissao';
import { validaPermissaoDisabled, validaPermissaoReadOnly } from '../auth/permissaoActions';

import { sendForced, sendCancelForced } from '../plano_forcado/planoForcadoAction';
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const IntervaloValidacao = require('./intervaloValidacao');
const InputValidacao = require('../common/form/inputValidacao.js');

class IntervaloPlanoList extends Component {

    constructor(props) {
        super(props);

        this.componentRef = React.createRef();

        this.renderEditable = this.renderEditable.bind(this);
        this.iniciaSimulacao = this.iniciaSimulacao.bind(this);
        this.salvaIntervalo = this.salvaIntervalo.bind(this);
        this.lerPlanoControlador = this.lerPlanoControlador.bind(this);
        this.lerPlanoDB = this.lerPlanoDB.bind(this);
        this.salvaIntervaloSerial = this.salvaIntervaloSerial.bind(this);
        // só pra teste
        this.salvaIntervaloDB = this.salvaIntervaloDB.bind(this);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleGetList = this.handleGetList.bind(this);

        this.inputChange = this.inputChange.bind(this);
        this.cicloMaximoOnBlur = this.cicloMaximoOnBlur.bind(this);
        this.copiarPlano = this.copiarPlano.bind(this);
        this.iniciaTempoReal = this.iniciaTempoReal.bind(this);
        this.executaTempoReal = this.executaTempoReal.bind(this);
        // this.simulaTempoReal = this.simulaTempoReal.bind(this);
        this.simulacao = this.simulacao.bind(this);
        this.ativarPlanoIntermitente = this.ativarPlanoIntermitente.bind(this);
        this.desativarPlanoIntermitente = this.desativarPlanoIntermitente.bind(this);
        this.modoPiscante = this.modoPiscante.bind(this);
        this.adicionaIntervalo = this.adicionaIntervalo.bind(this);
        this.removeIntervalo = this.removeIntervalo.bind(this);
        this.exibeImagem = this.exibeImagem.bind(this);
        this.onChangeAnel = this.onChangeAnel.bind(this);

        this.nIntervId = 0;
        this.tempoRealId = 0;
        this.intermitenteId = 0;
        this.segundos = 0.0;
        this.tempoIntervalo = [];
        this.coresFase = [];
        this.pngFase = [];
        this.countTempo = 0;
        this.corIdAtual = 0;
        this.segundoAtual = 0;
        this.intervaloAnterior = 0;
        this.intervaloTempoReal = 0;

        this.numeroFaseAtual = 0;
        this.totalFases = 0;

        this.state = {
            show: false,
            loop: 0,
            tempoRealAtivo: false,
            disabledbtn: false,
            disabledbtnSimula: false,
            disabledbtnTempoReal: false,
            disabledbtnplano: getConfig('disabledbtnplano'),
            tempoRealPlano: 0,
            modOperacaoDesc: [
                'Isolado/Normal',
                'Isolado/Atuado',
                'Isolado Atuado com Sequência Lógica',
                'Sincronizado/Normal',
                'Sincronizado/Atuado',
                'Sincronizado Atuado com Sequência Lógica'
            ],
            chageChave: false,
            modo: '',
            tipoCiclo: '',
            defasagem: 0,
            tempoDecorrido: 0,
            dataPlano: null,
            dataAnel: null,
            dataNumControlador: null,
            dataControlador: null,
            dataGrupo: null,
            list: [],
        };
    }

    ativarPlanoIntermitente() {
        swal({
            // title: 'Ativar o "Plano Intermitente" para os Controladores do mesmo grupo físico?',
            title: 'Ativar o "Plano Intermitente"?',
            html: '<h5>Essa ação irá ativar o plano 63.</h5>',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.setState({ show: true });
                const list = this.state.list.length > 0 ? this.state.list : this.props.list || [];
                let contId = list[0].controlador_id;

                let value = {
                    grupoFisicoId: 0,
                    contId: contId,
                    pf_anel: 5, // todos os anéis
                    plano_id: 63,
                    horario_inicial: "00:00:00",
                    horario_final: "23:59:59",
                }

                this.props.sendForced(value)
                    .then((result) => {
                        this.setState({ show: false });

                        toastr.success('Sucesso', 'Plano intermitente ativado com sucesso.');
                        this.setState({ disabledbtnplano: true });
                        setConfig('disabledbtnplano', true);
                    })
                    .catch(e => {
                        e.response.data.errors.forEach(error => toastr.error('Erro', error));
                        this.setState({ show: false });
                        // se der erro em um controlador e nos outros não, o usuário não vai conseguir desativar o intermitente

                        this.setState({ disabledbtnplano: true });
                        setConfig('disabledbtnplano', true);
                    });
            }
        });
    }

    desativarPlanoIntermitente() {
        swal({
            title: 'Desativar o "Plano Intermitente"?',
            html: '<h5>Essa ação irá desativar o plano 63.</h5>',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.setState({ show: true });

                const list = this.state.list.length > 0 ? this.state.list : this.props.list || [];
                let contId = list[0].controlador_id;

                let value = {
                    grupoFisicoId: 0,
                    contId: contId,
                    pf_anel: 5, // todos os anéis
                    plano_id: 63,
                    horario_inicial: "00:00:00",
                    horario_final: "23:59:59",
                }

                this.props.sendCancelForced(value)
                    .then((result) => {
                        this.setState({ show: false });

                        toastr.success('Sucesso', 'Plano intermitente desativado com sucesso.');
                        this.setState({ disabledbtnplano: false });
                        setConfig('disabledbtnplano', false);
                    })
                    .catch((e) => {
                        e.response.data.errors.forEach(error => toastr.error('Erro', error));
                        this.setState({ show: false });
                        this.setState({ disabledbtnplano: false });
                        setConfig('disabledbtnplano', false);
                    });
            }
        });
    }

    componentWillMount() {
        this.setState({ dataPlano: this.props['data-plano'] });
        this.setState({ dataAnel: this.props['data-anel'] });
        this.setState({ dataControlador: this.props['data-controlador'] });
        this.setState({ dataNumControlador: this.props['data-num-controlador'] });
        this.setState({ dataGrupo: this.props['data-grupo'] });
        this.props.getList(this.props['data-controlador'], this.props['data-plano'], this.props['data-anel'])
            .then((result) => {
                this.numeroFaseAtual = result.payload.data[0].faseFin;
                this.setState({ list: result.payload.data });
            });
    }

    componentWillUnmount() {
        clearInterval(this.nIntervId);
        clearInterval(this.tempoRealId);
        clearInterval(this.intermitenteId);
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    async handleGetList() {
        this.props.getList(this.state.dataControlador, this.state.dataPlano, this.state.dataAnel).then((result) => {
            this.setState({ list: result.payload.data });
        });
    }

    lerPlanoDB() {

        this.setState({ show: true });
        this.props.getList(this.state.dataControlador, this.state.dataPlano, this.state.dataAnel)
            .then((result) => {
                this.setState({ show: false });
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
            })
            .catch(e => {
                this.setState({ show: false });
                toastr.error('Erro ao ler do banco', e);
            });
    }

    lerPlanoControlador() {

        this.setState({ show: true });

        this.props.getListInterval(this.state.dataPlano, this.state.dataAnel, this.state.dataNumControlador, this.state.dataGrupo)
            .then((result) => {

                this.setState({ show: false });

                if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                    toastr.error('Erro', result.payload.response.data.errors[0]);
                    this.handleGetList();
                } else {
                    toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                }

            })
            .catch(e => {
                this.setState({ show: false });
                toastr.error('Erro', 'Sem conexão. Verifique se a central está conectada.');
                this.handleGetList();
            });
    }

    async salvaIntervalo() {
        this.salvaIntervaloSerial();
    }

    async salvaIntervaloSerial() {
        const list = this.state.list.length > 0 ? this.state.list : this.props.list || [];
        if (IntervaloValidacao.validaRegrasIntervalo(list)) {
            let confirma = false;
            await swal.fire({
                title: 'Enviar as configurações desse plano para o "Controlador"?',
                html: '<h5>Essa ação vai substituir as configurações existentes na central.</h5>',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: 'primary',
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
            }).then((result) => {
                confirma = result.value;
            })

            await sleep(1000);

            if (confirma) {
                this.setState({ show: true }); // da show no modal de aguarde
                const data = this.state.list.length > 0 ? this.state.list : this.props.list || [];
                console.log(data);
                this.props.updateSerial(data,this.state.dataControlador, this.state.dataNumControlador)
                    .then(async (result) => {

                        if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                            toastr.error('Erro', result.payload.response.data.errors[0]);
                        } else {
                            this.setState({ show: false });

                            await html2canvas(this.componentRef.current)
                                .then((canvas) => {
                                    canvas.style.display = 'none';
                                    let image = canvas.toDataURL("png");
                                    data[0]['imageLog'] = {
                                        numCont: this.state.dataNumControlador,
                                        grupoId: this.state.dataGrupo,
                                        anelId: this.state.dataAnel,
                                        image: image
                                    };
                                });
                            this.props.updatePlano(data[0])
                                .then((result) => {
                                    for (let key in data) {
                                        this.props.update(data[key]);
                                    }
                                    this.props.initPlano();
                                    this.setState({ show: false });
                                })
                                .catch(e => {
                                    this.setState({ show: false });
                                    toastr.error('Erro', 'Erro ao salvar os dados no banco de dados');
                                });
                            toastr.success('Sucesso', 'Plano enviado com sucesso.');
                        }

                    })
                    .catch(e => {
                        this.setState({ show: false });
                        // toastr.error('Erro', 'Sem conexão. Verifique se a central está conectada.');
                        e.response.data.errors.forEach(error => toastr.error('Erro', error));
                    });

            }
        }
    }
    // isso existe só para testar, em produção não deve salvar sem enviar para o controlador
    async salvaIntervaloDB() {
        const list = this.state.list.length > 0 ? this.state.list : this.props.list || [];
        if (IntervaloValidacao.validaRegrasIntervalo(list)) {

            this.setState({ show: true }); // da show no modal de aguarde

            const data = list;

            await html2canvas(this.componentRef.current)
                .then((canvas) => {
                    canvas.style.display = 'none';
                    let image = canvas.toDataURL("png");
                    data[0]['imageLog'] = {
                        numCont: this.state.dataNumControlador,
                        grupoId: this.state.dataGrupo,
                        anelId: this.state.dataAnel,
                        image: image
                    };
                });

            this.props.updatePlano(data[0])
                .then((result) => {
                    for (let key in data) {
                        this.props.update(data[key]);
                    }
                    this.props.initPlano();
                    this.setState({ show: false });
                })
                .catch(e => {
                    this.setState({ show: false });
                    toastr.error('Erro', 'Erro ao salvar os dados no banco de dados');
                });

        }
    }

    renderEditable(cellInfo) {
        const list = this.state.list.length > 0 ? this.state.list : this.props.list || [];
        let valorAtual = list[cellInfo.index][cellInfo.column.id];
        let modoDesabled = '';
        let classOptionHidden = '';
        let classOptionNDHidden = '';
        let SLEditable = true;
        let col_id = 0;
        let classModValid = '';
        let classNDValid = '';
        let classNDPressionado = '';

        if (cellInfo.original.descricao === 'Mod') {

            if ([0, 3].indexOf(parseInt(list[cellInfo.index].modo_operacao_id)) >= 0) {
                classOptionHidden = 'option_hidden';
            }

            col_id = cellInfo.column.id.split('_');

            if (([1, 3].indexOf(parseInt(cellInfo.original[`col_${parseInt(col_id[1]) - 1}`])) >= 0) ||
                ([1, 3].indexOf(parseInt(cellInfo.original[`col_${parseInt(col_id[1]) + 1}`])) >= 0)) {
                modoDesabled = 'disabled';
            }

            if (parseInt(col_id[1]) === parseInt(list[0].numero_intervalo)) {
                modoDesabled = 'disabled';
            }

            if ([1, 3, 5, 6].indexOf(parseInt(cellInfo.original[`col_${parseInt(col_id[1])}`])) >= 0) {
                for (const key in list) {
                    if ((list[key].descricao === 'TpM') && (parseFloat(list[key][cellInfo.column.id]) < 3)) {
                        list[key][cellInfo.column.id] = 3;
                    }
                }
            }

            if ([5, 6].indexOf(parseInt(cellInfo.original[`col_${parseInt(col_id[1])}`])) >= 0) {
                for (const key in list) {
                    if (list[key].descricao === 'AvM') {
                        window.$(`.class_avm_${cellInfo.column.id}`).css('border', 'solid #38B0DE 2px');
                        if (parseFloat(list[key][cellInfo.column.id]) < 6)
                            list[key][cellInfo.column.id] = 6;
                    } else {
                        if (list[key].descricao === 'Av+') {
                            window.$(`.class_av_${cellInfo.column.id}`).css('border', 'solid #38B0DE 2px');
                            if (parseFloat(list[key][cellInfo.column.id]) < 3)
                                list[key][cellInfo.column.id] = 3;
                        }
                    }
                }
            } else {
                for (const key in list) {// se não for 5 e 6 tem que voltar a cor ao normal para o AvM e Av+
                    let descTeste = list[key].descricao;
                    if (['AvM', 'Av+'].indexOf(descTeste) >= 0) {
                        window.$(`.class_avm_${cellInfo.column.id}`).css('border', 'solid #cccccc 1px');
                        window.$(`.class_av_${cellInfo.column.id}`).css('border', 'solid #cccccc 1px');
                    }
                }
            }

            let classeMod = ['sel-nenhum', 'mod-p', 'mod-s', 'mod-a', 'mod-sa', 'mod-pp', 'mod-aa'];
            let hiddenMod = ['', '', '', '', '', '', '', '', '', '', ''];

            if (cellInfo.column.id === 'col_1')
                hiddenMod = ['hidden', '', 'hidden', 'hidden', 'hidden', '', 'hidden', 'hidden', 'hidden', 'hidden', 'hidden'];

            classModValid = `class_mod_${cellInfo.column.id}`;

            return (
                <Select
                    id={`select_modo_${cellInfo.column.id}`}
                    className={`select-gridPlano select-gridPlano-mod class_borda_${cellInfo.column.id} ${classeMod[valorAtual]} ${classModValid}`}
                    contentEditable
                    suppressContentEditableWarning
                    onChange={event => {

                        if (validaPermissaoDisabled('CadIntervaloPlano', 'intervalo_nd_sl') === '') {
                            const data = [...list];
                            let col_id = 0;

                            if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {

                                this.setState({ chageChave: true });

                                if ([98, 99].indexOf(parseInt(event.target.value)) < 0) {
                                    data[cellInfo.index][cellInfo.column.id] = event.target.value;
                                } else {
                                    if (parseInt(event.target.value) === 98) {
                                        this.adicionaIntervalo(cellInfo.column.id);
                                    } else {
                                        this.removeIntervalo(cellInfo.column.id);
                                    }
                                }

                                this.setState({ data });

                                if ([1, 2].indexOf(parseInt(event.target.value)) >= 0) {
                                    window.$(`.class_mod_${cellInfo.column.id}`).css('border', 'solid #cccccc 2px');
                                }

                                col_id = cellInfo.column.id.split('_');
                                if ([1, 3, 5, 6].indexOf(parseInt(event.target.value)) >= 0) {

                                    window.$(`#select_modo_col_${parseInt(col_id[1]) + 1} option`).each(function (option) {
                                        window.$(this).removeAttr('disabled');
                                        if ([1, 3, 5, 6].indexOf(parseInt(window.$(this).val())) >= 0) {
                                            window.$(this).attr('disabled', 'disabled')
                                        }
                                    });
                                    window.$(`#select_modo_col_${parseInt(col_id[1]) - 1} option`).each(function (option) {
                                        window.$(this).removeAttr('disabled');
                                        if ([1, 3, 5, 6].indexOf(parseInt(window.$(this).val())) >= 0) {
                                            window.$(this).attr('disabled', 'disabled')
                                        }
                                    });
                                } else {
                                    window.$(`#select_modo_col_${parseInt(col_id[1]) + 1} option`).each(function (option) {
                                        if ([1, 3].indexOf(parseInt(window.$(this).val())) < 0) {
                                            window.$(this).removeAttr('disabled');
                                        }
                                    });
                                    window.$(`#select_modo_col_${parseInt(col_id[1]) - 1} option`).each(function (option) {
                                        if ([1, 3].indexOf(parseInt(window.$(this).val())) < 0) {
                                            window.$(this).removeAttr('disabled');
                                        }
                                    });
                                }

                                if ([7, 8].indexOf(parseInt(event.target.value)) >= 0) {
                                    for (const key in data) {
                                        if (data[key].descricao[0] === 'F') {
                                            data[key][cellInfo.column.id] = 0;
                                        }
                                    }
                                    data[cellInfo.index][cellInfo.column.id] = (parseInt(event.target.value) === 7) ? 1 : 2;
                                }
                            }
                        }
                    }}

                    value={list[cellInfo.index][cellInfo.column.id]}

                    disabled={validaPermissaoDisabled('CadIntervaloPlano', 'intervalo_mod_fase')}

                >
                    <option value='0' hidden={hiddenMod[0]} className='sel-nenhum'></option>
                    <option value='1' hidden={hiddenMod[1]} className='mod-p' disabled={modoDesabled} >&nbsp;P&nbsp;&nbsp;=&nbsp;&nbsp;Principal</option>
                    <option value='2' hidden={hiddenMod[2]} className='mod-s' >&nbsp;S&nbsp;&nbsp;=&nbsp;&nbsp;Secundário</option>
                    <option value='3' hidden={hiddenMod[3]} className={`mod-a ${classOptionHidden}`} disabled={modoDesabled}>&nbsp;A&nbsp;&nbsp;=&nbsp;&nbsp;Atuado</option>
                    <option value='4' hidden={hiddenMod[4]} className={`mod-sa ${classOptionHidden}`}>SA&nbsp;&nbsp;=&nbsp;&nbsp;Sec. Alternativo</option>

                    <option value='5' hidden={hiddenMod[5]} className='mod-pp' disabled={modoDesabled} >&nbsp;p&nbsp;&nbsp;=&nbsp;&nbsp;Principal Variável</option>
                    <option value='6' hidden={hiddenMod[6]} className={`mod-aa ${classOptionHidden}`} disabled={modoDesabled}>&nbsp;a&nbsp;&nbsp;=&nbsp;&nbsp;Atuado Variável</option>

                    <option value='7' hidden={hiddenMod[7]} className='mod-p' disabled={modoDesabled} >&nbsp;X&nbsp;&nbsp;=&nbsp;&nbsp;Principal Apagado</option>
                    <option value='8' hidden={hiddenMod[8]} className='mod-s' >&nbsp;x&nbsp;&nbsp;=&nbsp;&nbsp;Secundário Apagado</option>

                    <option value='98' hidden={hiddenMod[9]} className='sel-nenhum' >(+) Adicionar Intervalo</option>
                    <option value='99' hidden={hiddenMod[10]} className='sel-nenhum' >(-) Remover Intervalo</option>

                </Select>
            );
            // }
        }

        if (['TpN', 'ND', 'TpM', 'SL', 'Av+', 'AvM'].indexOf(cellInfo.original.descricao) >= 0) {

            if (cellInfo.original.descricao === 'ND') {// se for ND deve ferificar se é atuado para gerar o combobox

                // se não for atuado, não mostra nada no select ND
                if ([3, 5, 6].indexOf(parseInt(list[0][cellInfo.column.id])) < 0) {
                    classOptionNDHidden = 'option_hidden';
                    list[cellInfo.index][cellInfo.column.id] = 0;
                } else {
                    classOptionNDHidden = '';
                }

                classNDValid = `class_nd_${cellInfo.column.id}`;
                classNDPressionado = `class_nd_${list[cellInfo.index][cellInfo.column.id]}`;

                return (
                    <Select
                        id={`select_numdetector_${cellInfo.column.id}`}
                        className={`select-gridPlano select-gridPlano-nd class_borda_${cellInfo.column.id} ${classNDValid} ${classNDPressionado}`}
                        contentEditable
                        suppressContentEditableWarning
                        onChange={event => {

                            if (validaPermissaoDisabled('CadIntervaloPlano', 'intervalo_nd_sl') === '') {
                                const data = [...list];

                                if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {
                                    this.setState({ chageChave: true });
                                    if (parseInt(event.target.value) > 0) {
                                        window.$(`.class_nd_${cellInfo.column.id}`).css('border', 'solid #cccccc 2px');
                                    }

                                    data[cellInfo.index][cellInfo.column.id] = event.target.value;
                                    this.setState({ data });
                                }
                            }

                        }}

                        disabled={validaPermissaoDisabled('CadIntervaloPlano', 'intervalo_nd_sl')}

                        value={list[cellInfo.index][cellInfo.column.id]}

                    >
                        <option value='0' className={classOptionNDHidden} > </option>
                        <option value='1' className={classOptionNDHidden} >B1&nbsp;&nbsp;=&nbsp;&nbsp;Botão 1</option>
                        <option value='2' className={classOptionNDHidden} >B2&nbsp;&nbsp;=&nbsp;&nbsp;Botão 2</option>
                        <option value='3' className={classOptionNDHidden} >B3&nbsp;&nbsp;=&nbsp;&nbsp;Botão 3</option>
                        <option value='4' className={classOptionNDHidden} >B4&nbsp;&nbsp;=&nbsp;&nbsp;Botão 4</option>

                        <option value='5' className={classOptionNDHidden}  >L1&nbsp;&nbsp;=&nbsp;&nbsp;Laço 1</option>
                        <option value='6' className={classOptionNDHidden}  >L2&nbsp;&nbsp;=&nbsp;&nbsp;Laço 2</option>
                        <option value='7' className={classOptionNDHidden}  >L3&nbsp;&nbsp;=&nbsp;&nbsp;Laço 3</option>
                        <option value='8' className={classOptionNDHidden}  >L4&nbsp;&nbsp;=&nbsp;&nbsp;Laço 4</option>
                        <option value='9' className={classOptionNDHidden}  >L5&nbsp;&nbsp;=&nbsp;&nbsp;Laço 5</option>
                        <option value='10' className={classOptionNDHidden} >L6&nbsp;&nbsp;=&nbsp;&nbsp;Laço 6</option>
                        <option value='11' className={classOptionNDHidden} >L7&nbsp;&nbsp;=&nbsp;&nbsp;Laço 7</option>
                        <option value='12' className={classOptionNDHidden} >L8&nbsp;&nbsp;=&nbsp;&nbsp;Laço 8</option>

                        <option value='13' className={classOptionNDHidden} >L9&nbsp;&nbsp;=&nbsp;&nbsp;Laço 9</option>
                        <option value='14' className={classOptionNDHidden} >L10&nbsp;&nbsp;=&nbsp;&nbsp;Laço 10</option>
                        <option value='15' className={classOptionNDHidden} >L11&nbsp;&nbsp;=&nbsp;&nbsp;Laço 11</option>
                        <option value='16' className={classOptionNDHidden} >L12&nbsp;&nbsp;=&nbsp;&nbsp;Laço 12</option>
                        <option value='17' className={classOptionNDHidden} >L13&nbsp;&nbsp;=&nbsp;&nbsp;Laço 13</option>
                        <option value='18' className={classOptionNDHidden} >L14&nbsp;&nbsp;=&nbsp;&nbsp;Laço 14</option>
                        <option value='19' className={classOptionNDHidden} >L15&nbsp;&nbsp;=&nbsp;&nbsp;Laço 15</option>
                        <option value='20' className={classOptionNDHidden} >L16&nbsp;&nbsp;=&nbsp;&nbsp;Laço 16</option>
                    </Select>
                );

            } else {
                if (cellInfo.original.descricao === 'SL') {

                    // let inputEnabled = '';
                    let classOptionSLHidden = '';
                    // 1 - Principal, 3 - Atuado, 6 - Atuado Variável
                    if ([1, 3, 6].indexOf(parseInt(list[0][cellInfo.column.id])) < 0) {
                        classOptionSLHidden = 'option_hidden';
                        SLEditable = false;
                        list[cellInfo.index][cellInfo.column.id] = 0;
                    } else {
                        if (cellInfo.column.id === 'col_1') {
                            SLEditable = false;
                            list[cellInfo.index][cellInfo.column.id] = 0;
                        } else {
                            SLEditable = true;
                        }

                    }

                    if (validaPermissaoReadOnly('CadIntervaloPlano', 'intervalo_nd_sl')) {// se readOnly for true, o editavel dever ser false
                        SLEditable = false;
                        // inputEnabled = 'input-gridPlano-enabled';
                    }

                    let classSLValid = `class_nd_${cellInfo.column.id}`;
                    let classSlPressionado = `class_nd_${list[cellInfo.index][cellInfo.column.id]}`;

                    return (

                        <Select
                            id={`select_numdetector_${cellInfo.column.id}`}
                            className={`select-gridPlano select-gridPlano-nd class_borda_${cellInfo.column.id} ${classSLValid} ${classSlPressionado}`}
                            contentEditable
                            suppressContentEditableWarning
                            onChange={event => {

                                if (validaPermissaoDisabled('CadIntervaloPlano', 'intervalo_nd_sl') === '') {
                                    const data = [...list];

                                    if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {
                                        this.setState({ chageChave: true });
                                        if (parseInt(event.target.value) > 0) {
                                            window.$(`.class_nd_${cellInfo.column.id}`).css('border', 'solid #cccccc 2px');
                                        }

                                        data[cellInfo.index][cellInfo.column.id] = event.target.value;
                                        this.setState({ data });
                                    }
                                }

                            }}

                            // disabled={validaPermissaoDisabled('CadIntervaloPlano', 'intervalo_nd_sl')}

                            value={list[cellInfo.index][cellInfo.column.id]}

                        >
                            <option value='0' className={classOptionSLHidden} >&nbsp;0</option>
                            <option value='1' className={classOptionSLHidden} >&nbsp;1</option>
                            <option value='2' className={classOptionSLHidden} >&nbsp;2</option>
                            <option value='3' className={classOptionSLHidden} >&nbsp;3</option>
                            <option value='4' className={classOptionSLHidden} >&nbsp;4</option>
                            <option value='5' className={classOptionSLHidden} >&nbsp;5</option>
                            <option value='6' className={classOptionSLHidden} >&nbsp;6</option>
                            <option value='7' className={classOptionSLHidden} >&nbsp;7</option>
                            <option value='8' className={classOptionSLHidden} >&nbsp;8</option>
                            <option value='9' className={classOptionSLHidden} >&nbsp;9</option>
                        </Select>
                    );

                } else {
                    let valorHTML = list[cellInfo.index][cellInfo.column.id];
                    let classTpNZero = '';
                    let classAv = '';
                    let classAvM = '';
                    let inputEnabled = '';

                    // quando carrega remove os decimais se for P ou A
                    if (cellInfo.original.descricao === 'TpN') {
                        if (['1', '3', '5', '6'].indexOf(list[0][cellInfo.column.id]) >= 0) {
                            valorHTML = parseFloat(list[cellInfo.index][cellInfo.column.id]).toFixed(0);
                        } else {
                            valorHTML = parseFloat(list[cellInfo.index][cellInfo.column.id]).toFixed(1);
                        }
                        // é utilizado para pintar os campos com valor zerado
                        classTpNZero = `class_tpn_${cellInfo.column.id}`;
                    }

                    if (cellInfo.original.descricao === 'Av+') {
                        classAv = `class_av_${cellInfo.column.id}`;
                        valorHTML = parseFloat(list[cellInfo.index][cellInfo.column.id] / 10).toFixed(1);
                    }

                    if (cellInfo.original.descricao === 'AvM') {
                        classAvM = `class_avm_${cellInfo.column.id}`;
                    }

                    SLEditable = true;

                    if (validaPermissaoReadOnly('CadIntervaloPlano', 'intervalo_tpn_tpm')) {// se readOnly for true, o editavel dever ser false
                        SLEditable = false;
                        inputEnabled = 'input-gridPlano-enabled';
                    }
                    return (
                        <div
                            style={{
                                backgroundColor: "#fafafa",
                                textAlign: 'center'
                            }}
                            contentEditable={SLEditable}
                            suppressContentEditableWarning
                            className={`input-gridPlano class_borda_${cellInfo.column.id} ${classTpNZero} ${inputEnabled} ${classAv} ${classAvM}`}
                            onKeyDown={evento => { // aceita só números
                                // se for P ou A deve validar só inteiros
                                if (cellInfo.original.descricao === 'TpN') {
                                    if (['1', '3', '5', '6'].indexOf(list[0][cellInfo.column.id]) >= 0) {
                                        return InputValidacao.validaSoNumeroInteiro(evento);
                                    }
                                }// se não pode validar decimal
                                return InputValidacao.validaSoNumeroDecimal(evento);
                                // return evento;

                            }}
                            onFocus={e => {
                                var cell = e.target;
                                var range, selection;
                                if (document.body.createTextRange) {
                                    range = document.body.createTextRange();
                                    range.moveToElementText(cell);
                                    range.select();
                                } else if (window.getSelection) {
                                    selection = window.getSelection();
                                    range = document.createRange();
                                    range.selectNodeContents(cell);
                                    selection.removeAllRanges();
                                    selection.addRange(range);
                                }
                            }}

                            onBlur={e => {
                                if (validaPermissaoDisabled('CadIntervaloPlano', 'intervalo_tpn_tpm') === '') {
                                    const data = [...list];

                                    if (data[cellInfo.index][cellInfo.column.id] != e.target.innerHTML) {

                                        let valorDigitado = e.target.innerHTML || 0;

                                        if (cellInfo.original.descricao === 'TpN') {

                                            valorDigitado = IntervaloValidacao.validaTempoIntervalo(data, cellInfo.column.id, valorDigitado);

                                            // valorDigitado = IntervaloValidacao.validaTempoSecundarioTempoMinimo(data, cellInfo.column.id, valorDigitado);
                                            data[cellInfo.index][cellInfo.column.id] = valorDigitado;

                                            window.$(`.class_tpn_${cellInfo.column.id}`).css('border', 'solid #cccccc 2px');
                                            window.$(`.class_tpn_${cellInfo.column.id}`).css('font-weight', 'normal');
                                            window.$(`.class_tpn_${cellInfo.column.id}`).css('color', '#000000');
                                            if (parseFloat(data[cellInfo.index][cellInfo.column.id]) <= 0) {

                                                window.$(`.class_tpn_${cellInfo.column.id}`).css('border', 'solid #FF7F00 2px');
                                                window.$(`.class_tpn_${cellInfo.column.id}`).css('font-weight', 'bold');
                                                window.$(`.class_tpn_${cellInfo.column.id}`).css('color', '#FF0000');
                                            }


                                            e.target.innerHTML = data[cellInfo.index][cellInfo.column.id];
                                            data[0].ciclo_maximo = IntervaloValidacao.getValorCicloMaximo(data, cellInfo.index);

                                            data[0].ciclo_tempo_total = IntervaloValidacao.somaCicloTempoTotal(data);

                                            for (var key in data) {
                                                data[key].ciclo_maximo = data[0].ciclo_maximo;
                                                this.setState({ data });
                                            }
                                        } else {
                                            if (cellInfo.original.descricao === 'Av+' && parseFloat(valorDigitado) !== 0) {
                                                let vlAVM = parseInt(data[cellInfo.index + 1][cellInfo.column.id]);
                                                valorDigitado = (parseInt(valorDigitado) < 3) ? 3 : valorDigitado;
                                                vlAVM = (vlAVM < 6) ? 6 : vlAVM;
                                                vlAVM = (vlAVM < parseInt(valorDigitado)) ? valorDigitado : vlAVM;

                                                data[cellInfo.index + 1][cellInfo.column.id] = vlAVM;
                                            } else {
                                                if (cellInfo.original.descricao === 'AvM') {
                                                    let vlAV = parseInt(data[cellInfo.index - 1][cellInfo.column.id]);
                                                    valorDigitado = (parseInt(valorDigitado) < 6) ? 6 : valorDigitado;

                                                    if (vlAV > parseInt(valorDigitado)) {
                                                        valorDigitado = vlAV;
                                                    }
                                                }
                                            }

                                            data[cellInfo.index][cellInfo.column.id] = valorDigitado;
                                            e.target.innerHTML = data[cellInfo.index][cellInfo.column.id];
                                            this.setState({ data });
                                        }
                                    }
                                }

                            }}

                            dangerouslySetInnerHTML={{
                                __html: valorHTML
                            }}
                        />
                    );
                }
            }


        } else {
            var classe = ['sel-nenhum', 'sel-vermelho', 'sel-amarelo', 'sel-verde', 'sel-vermelho-p', 'sel-amarelo-p', 'sel-verde-p'];
            let faseDesabled = '';
            let faseAmareloDesabled = '';
            let faseVermelhoDesabled = '';
            let classFValida = '';

            let validacaoConflitanate = IntervaloValidacao.validaVerdeAmarelo(list, cellInfo.column.id, cellInfo.original.descricao);
            if (parseInt(validacaoConflitanate) === 0) {
                faseDesabled = 'disabled';
                faseAmareloDesabled = 'disabled';
            } else {
                if (parseInt(validacaoConflitanate) === 1) {
                    faseDesabled = 'disabled';
                } else {
                    if (parseInt(validacaoConflitanate) === 3) {
                        faseVermelhoDesabled = 'disabled';
                    } else {
                        if (parseInt(validacaoConflitanate) === 4) {
                            faseDesabled = 'disabled';
                            faseVermelhoDesabled = 'disabled';
                        }
                    }
                }
            }

            classFValida = `class_f_${cellInfo.original.descricao}_${cellInfo.column.id}`;

            return (
                <Select
                    className={`select-gridPlano ${classe[valorAtual]} class_borda_${cellInfo.column.id} ${classFValida}`}
                    contentEditable
                    suppressContentEditableWarning
                    onChange={event => {

                        if (validaPermissaoDisabled('CadIntervaloPlano', 'intervalo_mod_fase') === '') {
                            const data = [...list];

                            if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {

                                this.setState({ chageChave: true });
                                // let id_col = parseInt(cellInfo.column.id.split('_')[1]);

                                if ([2, 4].indexOf(parseInt(event.target.value)) >= 0) {
                                    window.$(`.${classFValida}`).css('border', 'solid #cccccc 1px');
                                }

                                if (faseDesabled === '') {
                                    data[cellInfo.index][cellInfo.column.id] = event.target.value;
                                } else {

                                    if (faseAmareloDesabled === '') {
                                        if (['1', '2', '4', '5', '0'].indexOf(event.target.value) >= 0) {
                                            data[cellInfo.index][cellInfo.column.id] = event.target.value;
                                        }
                                    } else {
                                        if (['1', '4', '0', '5'].indexOf(event.target.value) >= 0) {
                                            data[cellInfo.index][cellInfo.column.id] = event.target.value;
                                        }
                                    }
                                }

                                this.setState({ data });
                            }
                        }

                    }}

                    value={list[cellInfo.index][cellInfo.column.id]}

                    disabled={validaPermissaoDisabled('CadIntervaloPlano', 'intervalo_mod_fase')}

                >
                    <option value='1' disabled={faseVermelhoDesabled} className='sel-vermelho'>1&nbsp;&nbsp;=&nbsp;&nbsp;Vermelho</option>
                    <option value='2' disabled={faseAmareloDesabled} className='sel-amarelo'>2&nbsp;&nbsp;=&nbsp;&nbsp;Amarelo</option>
                    <option value='3' disabled={faseDesabled} className='sel-verde'>3&nbsp;&nbsp;=&nbsp;&nbsp;Verde</option>
                    <option value='4' disabled={faseAmareloDesabled} className='sel-vermelho-p'>4&nbsp;&nbsp;=&nbsp;&nbsp;Vermelho Piscante</option>
                    <option value='5' className='sel-amarelo-p'>5&nbsp;&nbsp;=&nbsp;&nbsp;Amarelo Piscante</option>
                    <option value='6' disabled={faseDesabled} className='sel-verde-p'>6&nbsp;&nbsp;=&nbsp;&nbsp;Verde Piscante</option>
                    <option value='0' className='sel-nenhum'>0&nbsp;&nbsp;=&nbsp;&nbsp;Apagado</option>
                </Select>
            );

        }
    }

    carregaColunas() {
        const intervalo = this.state.list.length > 0 ? this.state.list : this.props.list || [];
        var columns = [];

        columns.push({
            accessor: 'descricao', // String-based value accessors!
            width: 40,
            style: {
                padding: '0px',
                height: '20px'
            }
        });

        var property = intervalo[0];

        var idColuna = 0;

        for (var key in property) {
            if (key.substring(0, 4) === 'col_') {
                idColuna += 1;
                columns.push({
                    Header: `${idColuna}`,
                    headerClassName: `class_${idColuna} class_borda_${idColuna}`,
                    accessor: `${key}`, // String-based  value accessors!
                    width: 34,
                    style: {
                        padding: '0px',
                        height: '20px'
                    },
                    Cell: this.renderEditable
                });
            }
        }

        return columns;
    }

    piscaCores(faseId, corId) {
        if (this.segundoAtual !== parseInt(this.segundos)) {
            this.segundoAtual = parseInt(this.segundos);

            if (this.corIdAtual == corId) {
                window.$("#imgSimulaF" + faseId).attr("src", `${this.pngFase[0]}`);
                this.corIdAtual = 0;
            } else {
                window.$("#imgSimulaF" + faseId).attr("src", `${this.pngFase[corId]}`);
                this.corIdAtual = corId;
            }
        }
    }

    modoPiscante(data) {

        let piscanteCor = (window.$('#imgSimulaF' + data[0].fase_id).attr('src') === this.pngFase[2]) ? this.pngFase[0] : this.pngFase[2];

        for (const key in data) {
            window.$('#imgSimulaF' + data[key].fase_id).attr('src', `${piscanteCor}`);
        }
    }

    simulacao() { // esse método é um loop de 1 segundo

        let cronometroIntervalo = 0;
        let faseIdx = this.numeroFaseAtual - this.coresFase.length + 1;
        if (this.segundos >= this.tempoIntervalo[this.countTempo]) {
            this.segundos = 0.0;
            this.countTempo += 1;
        }

        if (this.countTempo === 0) {
            window.$(".class_" + this.tempoIntervalo.length).css("background-color", "#ffffff");
        }
        window.$(".class_" + this.intervaloAnterior).css("background-color", "#ffffff");
        window.$(".class_" + (parseInt(this.countTempo) + 1)).css("background-color", "#FF7F00");

        window.$(".class_borda_col_" + this.intervaloAnterior).css("border", "solid #cccccc 1px");
        window.$(`.class_borda_col_${(parseInt(this.countTempo) + 1)}`).css('border', 'solid #FF7F00 2px');

        this.intervaloAnterior = (parseInt(this.countTempo) + 1);

        if (this.countTempo < this.tempoIntervalo.length) {

            this.segundos += 0.1;

            cronometroIntervalo = this.tempoIntervalo[this.countTempo] + 0.1 - this.segundos
            window.$('#cronometro').val(cronometroIntervalo.toFixed(1));

            for (var x in this.coresFase) {

                if (['1', '2', '3'].indexOf(this.coresFase[x][this.countTempo]) >= 0) {
                    window.$("#imgSimulaF" + faseIdx).attr("src", `${this.pngFase[this.coresFase[x][this.countTempo]]}`);
                } else {
                    this.piscaCores(faseIdx, this.coresFase[x][this.countTempo]);
                }
                faseIdx++;
            }

        } else {
            this.countTempo = 0;
            this.segundos = 0.1;
            cronometroIntervalo = this.tempoIntervalo[this.countTempo] + 0.1 - this.segundos
            window.$('#cronometro').val(cronometroIntervalo.toFixed(1));

            for (let x in this.coresFase) {
                window.$("#imgSimulaF" + (parseInt(x) + 1)).attr("src", `${this.pngFase[this.coresFase[x][this.countTempo]]}`);
            }
        }

    }

    iniciaSimulacao() {

        var corFase = '';

        this.segundos = 0.0;
        this.tempoIntervalo = [];
        this.pngFase[0] = trafficBranco;
        this.pngFase[1] = trafficVermelho;
        this.pngFase[2] = trafficAmarelo;
        this.pngFase[3] = trafficVerde;
        this.pngFase[4] = trafficVermelho;
        this.pngFase[5] = trafficAmarelo;
        this.pngFase[6] = trafficVerde;

        if (window.$("#btnSimular")[0].className.indexOf('success') > 0) {
            this.bloquearTela(true);
            this.setState({ disabledbtnTempoReal: true });
            const intervalo = this.state.list.length > 0 ? this.state.list : this.props.list || [];

            for (var key in intervalo) {
                // carrega o tempo
                if (intervalo[key]['descricao'] === 'TpN') {
                    for (var x in intervalo[key]) {
                        if (x.substring(0, 4) === 'col_') {
                            // if ((x != 'controlador_id') && (x != 'plano_id') && (x != 'descricao')) {

                            this.tempoIntervalo.push(parseFloat(intervalo[key][x].replace('.', ',')));
                        }
                    }
                }
                //carrega uma matriz com as cores
                if (intervalo[key]['descricao'][0] === 'F') {
                    for (let x in intervalo[key]) {
                        if (x.substring(0, 4) === 'col_') {
                            // if ((x != 'controlador_id') && (x != 'plano_id') && (x != 'descricao')) {
                            if (corFase === '') {
                                corFase = parseInt(intervalo[key][x]);
                            } else {
                                corFase += ',' + parseInt(intervalo[key][x]);
                            }
                        }
                    }
                    this.coresFase.push(corFase.split(','));
                    corFase = '';
                }
            }

            this.nIntervId = setInterval(this.simulacao, 100);

            window.$("#btnSimular")[0].innerHTML = '<i class="fa fa-stop"></i> <span>Parar</span>';
            window.$("#btnSimular")[0].className = 'btn btn-danger';
        } else {
            this.bloquearTela(false);
            this.setState({ disabledbtnTempoReal: false });
            window.$(".class_" + (parseInt(this.countTempo) + 1)).css("background-color", "#ffffff");
            window.$(".class_borda_col_" + (parseInt(this.countTempo) + 1)).css("border", "solid #cccccc 1px");
            this.intervaloAnterior = 0;

            this.segundos = 0.0;
            this.countTempo = 0;
            window.$('#cronometro').val(0);
            clearInterval(this.nIntervId);

            for (let x in this.coresFase) {
                window.$("#imgSimulaF" + (parseInt(x) + 1)).attr("src", `${traffic}`);
            }

            this.coresFase = [];

            window.$("#btnSimular")[0].innerHTML = '<i class="fa fa-play"></i> <span>Simular</span>';
            window.$("#btnSimular")[0].className = 'btn btn-success';
        }

    }

    inputChange(event) {
        const data = this.state.list.length > 0 ? this.state.list : this.props.list || [];
        for (var key in data) {
            data[key][event.target.name] = event.target.value;
            this.setState({ data });
        }
    }

    cicloMaximoOnBlur(event) {
        const data = this.state.list.length > 0 ? this.state.list : this.props.list || [];

        let somaCiclo = IntervaloValidacao.calculaCicloMaximo(data);

        if (parseFloat(event.target.value) >= somaCiclo) {
            somaCiclo = parseFloat(event.target.value);
        }

        if (parseFloat(event.target.value) >= 999) {
            somaCiclo = 999;
        }

        for (var key in data) {
            data[key].ciclo_maximo = somaCiclo;
            this.setState({ data });
        }
    }

    copiarPlano() {
        let planoCopyId = window.$('#selIntervaloCopiaPlano').val();
        if (planoCopyId != null) {
            if (planoCopyId > 0) {
                this.setState({ show: true });
                this.props.getList(this.state.dataControlador, planoCopyId, this.state.dataAnel)
                    .then(({ payload }) => {
                        let data = payload.data;
                        for (let key in data) {
                            data[key].plano_id = this.state.dataPlano;
                        }
                        this.setState({ data: data });
                        this.setState({ show: false });
                        toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                    })
                    .catch(e => {
                        this.setState({ show: false });
                        toastr.error('Erro', 'Sem conexão. Verifique se a central está conectada.');
                    });
            }
        }
    }

    simulaTempoReal(data, mod_piscante) {
        // window.$('.rt-th').css('background-color', '#ffffff');

        if ((window.$("#btnTempoReal")[0].className) && (window.$("#btnTempoReal")[0].className.indexOf('warning') < 0)) {
            if (data.length) {

                // window.$('.rt-th').css('background-color', '#ffffff');
                window.$(`.class_${this.intervaloTempoReal}`).css('background-color', '#ffffff');
                window.$(`.class_${this.intervaloTempoReal}`).css('font-weight', 'normal');
                window.$(`.class_${data[0].intervalo}`).css('background-color', '#ff8000');
                window.$(`.class_${data[0].intervalo}`).css('font-weight', 'bold');

                window.$(`.class_borda_col_${this.intervaloTempoReal}`).css('border', 'solid #cccccc 1px');
                window.$(`.class_borda_col_${this.intervaloTempoReal}`).css('font-weight', 'normal');
                window.$(`.class_borda_col_${data[0].intervalo}`).css('border', 'solid #FF7F00 2px');
                window.$(`.class_borda_col_${data[0].intervalo}`).css('font-weight', 'bold');

                this.intervaloTempoReal = data[0].intervalo;

                window.$('#cronometro').val(parseInt(data[0].tempo));

                window.$(`.class_nd_1`).css('background-color', (data[0].b1_pressionado) ? '#FF7F00' : '#FFFFFF');
                window.$(`.class_nd_2`).css('background-color', (data[0].b2_pressionado) ? '#FF7F00' : '#FFFFFF');
                window.$(`.class_nd_3`).css('background-color', (data[0].b3_pressionado) ? '#FF7F00' : '#FFFFFF');
                window.$(`.class_nd_4`).css('background-color', (data[0].b4_pressionado) ? '#FF7F00' : '#FFFFFF');

                this.setState({
                    modo: data[0].modo_operacao,
                    tipoCiclo: data[0].tipo_ciclo,
                    defasagem: data[0].defasagem,
                    tempoDecorrido: data[0].decorrente_ciclo
                });
                // if (data[0].mod_piscante) {
                if (mod_piscante) {
                    // this.setState({ disabledbtnplano: true });
                    this.modoPiscante(data);
                } else {
                    for (const key in data) {
                        // window.$('#cronometro').val(data[key].tempo);

                        if (data[key].fase_cor === 'Vermelho') {
                            window.$('#imgSimulaF' + data[key].fase_id).attr('src', `${this.pngFase[1]}`);
                        } else {
                            if (data[key].fase_cor === 'Amarelo') {
                                window.$('#imgSimulaF' + data[key].fase_id).attr('src', `${this.pngFase[2]}`);
                            } else {
                                if (data[key].fase_cor === 'Verde') {
                                    window.$('#imgSimulaF' + data[key].fase_id).attr('src', `${this.pngFase[3]}`);
                                } else { // Apagado
                                    // window.$('#imgSimulaF' + data[key].fase_id).attr('src', `${this.pngFase[0]}`);
                                }
                            }
                        }
                    }
                }
            }
        }
        this.setState({ loop: 0 });
    }

    executaIntermitente(data) {
        window.$("#btnTempoReal")[0].innerHTML = '<i class="fa fa-stop"></i> <span>Parar Tempo real do anel</span>';
        window.$("#btnTempoReal")[0].className = 'btn btn-danger';
        this.intermitenteId = setInterval(() => {
            this.modoPiscante(data);
        }, 500)
    }

    executaTempoReal() {
        window.$("#btnTempoReal")[0].innerHTML = '<i class="fa fa-stop"></i> <span>Parar Tempo real do anel</span>';
        window.$("#btnTempoReal")[0].className = 'btn btn-danger';
        this.tempoRealId = setInterval(() => {
            try {
                if (parseInt(this.state.loop) === 0) {
                    this.setState({ tempoRealAtivo: true });
                    this.setState({ loop: 1 });
                    this.props.getControllerStep(this.state.dataNumControlador, this.state.dataAnel, this.state.dataGrupo)
                        .catch((error) => {
                            this.setState({ tempoRealAtivo: false });
                            this.bloquearTela(false);
                            this.setState({ disabledbtnSimula: false });
                            this.setState({ show: false });
                            toastr.error('Erro', 'Dados incompletos. Conexão com o controlador foi perdida.');
                            this.handleGetList();
                        })
                        .then((result) => {
                            if ([49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63].indexOf(parseInt(result.payload.data[0].plano_id)) >= 0) {
                                this.setState({ tempoRealPlano: parseInt(result.payload.data[0].plano_id) });
                                this.simulaTempoReal(result.payload.data, true);
                            } else if (parseInt(result.payload.data[0].plano_id) === 0) {
                                this.setState({ tempoRealAtivo: false });
                                this.bloquearTela(false);
                                this.setState({ disabledbtnSimula: false });
                                this.setState({ show: false });
                                toastr.error('Erro', 'Dados incompletos. Conexão com o controlador foi perdida.');
                                this.handleGetList();
                                this.pararTempoReal();
                            } else {
                                if ((this.state.tempoRealPlano > 0) && (result.payload.data[0].plano_id !== this.state.tempoRealPlano)) {
                                    window.$('#titulointervaloplano').html(`Controlador ${this.state.dataNumControlador} - Anel ${result.payload.data[0].anel} - Plano ${result.payload.data[0].plano_id}`);
                                    this.setState({ tempoRealPlano: parseInt(result.payload.data[0].plano_id) });
                                    this.setState({ show: true });
                                    this.props.getListInterval(result.payload.data[0].plano_id, this.state.dataAnel, this.state.dataNumControlador, this.state.dataGrupo)
                                        .then((resultList) => {
                                            window.$('#modooperacaointervaloplano').html(this.state.modOperacaoDesc[resultList.payload.data[0].modo_operacao_id]);
                                            this.setState({ show: false });

                                            if (resultList.error) {//se retornar true no erro, chama o metodo que pega do banco
                                                toastr.error('Erro', resultList.payload.response.data.errors[0]);
                                                this.handleGetList();
                                            } else {
                                                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                                            }

                                            // this.executaTempoReal();
                                            this.simulaTempoReal(result.payload.data, false);

                                        })
                                        .catch((error) => {
                                            this.setState({ tempoRealAtivo: false });
                                            this.bloquearTela(false);
                                            this.setState({ disabledbtnSimula: false });
                                            this.setState({ show: false, modo: '' });
                                            toastr.error('Erro', 'Dados incompletos. Conexão com o controlador foi perdida.');
                                            this.handleGetList();
                                        });
                                } else {
                                    this.simulaTempoReal(result.payload.data, false);
                                }
                            }
                        });
                } 
            } catch (error) {
                this.setState({ loop: 0 });
            }
        }, 500);
    }

    iniciaTempoReal() {
        this.pngFase[0] = trafficBranco;
        this.pngFase[1] = trafficVermelho;
        this.pngFase[2] = trafficAmarelo;
        this.pngFase[3] = trafficVerde;
        this.pngFase[4] = trafficVermelho;
        this.pngFase[5] = trafficAmarelo;
        this.pngFase[6] = trafficVerde;

        if (window.$("#btnTempoReal")[0].className.indexOf('warning') > 0) {
            this.bloquearTela(true);
            this.setState({ disabledbtnSimula: true });
            this.setState({ show: true });

            this.props.getControllerStep(this.state.dataNumControlador, this.state.dataAnel, this.state.dataGrupo)
                .then((result) => {

                    if (result.payload.data.length > 0) {

                        this.totalFases = result.payload.data.length;

                        window.$('#titulointervaloplano').html(`Controlador ${this.state.dataNumControlador} - Anel ${result.payload.data[0].anel} - Plano ${result.payload.data[0].plano_id}`);

                        this.setState({ tempoRealPlano: parseInt(result.payload.data[0].plano_id) });

                        if ([49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63].indexOf(parseInt(result.payload.data[0].plano_id)) >= 0) {
                            this.executaTempoReal();
                            this.setState({ show: false });
                        } else {
                            this.props.getListInterval(result.payload.data[0].plano_id, this.state.dataAnel, this.state.dataNumControlador, this.state.dataGrupo)
                                .then((result) => {
                                    window.$('#modooperacaointervaloplano').html(this.state.modOperacaoDesc[result.payload.data[0].modo_operacao_id]);
                                    this.setState({ show: false });

                                    if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                                        toastr.error('Erro', result.payload.response.data.errors[0]);
                                        this.handleGetList();
                                    } else {
                                        toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                                    }

                                    this.executaTempoReal();

                                })
                                .catch((error) => {
                                    this.bloquearTela(false);
                                    this.setState({ disabledbtnSimula: false });
                                    this.setState({ show: false });
                                    toastr.error('Erro', 'Dados incompletos. Conexão com o controlador foi perdida.');
                                    this.handleGetList();
                                });
                        }
                    } else {
                        this.bloquearTela(false);
                        this.setState({ disabledbtnSimula: false });
                        this.setState({ show: false });
                        toastr.error('Erro', 'Dados incompletos. Conexão com o controlador foi perdida.');
                        this.handleGetList();
                    }
                });

        } else {
            this.pararTempoReal();
        }
    }


    pararTempoReal() {
        this.bloquearTela(false);
        this.setState({ disabledbtnSimula: false });
        // window.$(".rt-th").css("background-color", "#ffffff");

        window.$(`.class_nd_1`).css('background-color', '#FFFFFF');
        window.$(`.class_nd_2`).css('background-color', '#FFFFFF');
        window.$(`.class_nd_3`).css('background-color', '#FFFFFF');
        window.$(`.class_nd_4`).css('background-color', '#FFFFFF');

        window.$(`.class_${this.intervaloTempoReal}`).css('background-color', '#ffffff');
        window.$(`.class_${this.intervaloTempoReal}`).css('font-weight', 'normal');
        window.$(`.class_borda_col_${this.intervaloTempoReal}`).css("border", "solid #cccccc 1px");
        window.$(`.class_borda_col_${this.intervaloTempoReal}`).css('font-weight', 'normal');

        window.$('#cronometro').val(0);
        clearInterval(this.tempoRealId);
        clearInterval(this.intermitenteId);
        for (let index = 1; index <= this.totalFases; index++) {
            window.$("#imgSimulaF" + index).attr("src", `${traffic}`);
        }

        window.$("#btnTempoReal")[0].innerHTML = '<i class="fa fa-play"></i> <span>Tempo real do anel</span>';
        window.$("#btnTempoReal")[0].className = 'btn btn-warning';
    }

    bloquearTela(bloquear) {
        if (bloquear) {
            this.setState({ disabledbtn: true });
            window.$('select').attr('disabled', 'disabled');
            window.$('div .input-gridPlano').attr('contenteditable', 'false');
            window.$('input').attr('disabled', 'disabled');
        } else {
            this.setState({ disabledbtn: false });
            window.$('select').removeAttr('disabled');
            window.$('div .input-gridPlano').attr('contenteditable', 'true');
            window.$('input').removeAttr('disabled');
        }

    }

    adicionaIntervalo(column_id) {

        let data = this.state.list.length > 0 ? this.state.list : this.props.list || [];
        column_id = column_id.split('_');
        let colData = {};
        let colId = 0;
        let colCount = parseInt(column_id[1]);

        if (parseInt(data[0].numero_intervalo) < consts.NUM_INTERVALO) {  // era intervalo < 32

            for (const idx in data) {
                colData = {};
                for (const key in data[idx]) {

                    if (key.indexOf('col_') >= 0) {
                        colId = parseInt(key.split('_')[1]);
                        if (colId === colCount) {
                            // se for igual, deve receber o atual e depois adicionar um novo col_
                            colData[key] = data[idx][key];

                            // aqui ele gera a coluna nova com base na descricao, para isso ele soma 1 no id da coluna atual
                            if (data[idx].descricao === 'Mod') {
                                colData[`col_${colId + 1}`] = '2';
                            } else {
                                if (data[idx].descricao[0] === 'F') {
                                    colData[`col_${colId + 1}`] = '1';
                                } else {
                                    if (data[idx].descricao === 'TpN') {
                                        colData[`col_${colId + 1}`] = data[1].tempo_vermelho_min;
                                    } else {
                                        if (data[idx].descricao === 'ND') {
                                            colData[`col_${colId + 1}`] = 0;
                                        } else {
                                            if (data[idx].descricao === 'TpM') {
                                                colData[`col_${colId + 1}`] = "0";
                                            } else {
                                                if (data[idx].descricao === 'Av+') {
                                                    colData[`col_${colId + 1}`] = "0";
                                                } else {
                                                    if (data[idx].descricao === 'AvM') {
                                                        colData[`col_${colId + 1}`] = "0";
                                                    } else {

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            if (parseInt(data[0].numero_intervalo) === parseInt(colId)) {
                                colId++;
                            }

                        } else {
                            if (parseInt(colId) < colCount) { // se for menor que o atual, então só copia o existente
                                colData[key] = data[idx][key];
                            } else {// se for maior que a coluna atual, tem que incrementar 1 em todos as próximas colunas para jogar tudo um para frente
                                colId++;
                                colData[`col_${colId}`] = data[idx][key];
                            }
                        }
                    } else {
                        colData[key] = data[idx][key];
                    }
                }

                data[idx] = colData;
            }

            data[0].numero_intervalo++;// = colId;

            this.setState({ data });
        } else {
            toastr.warning('Alerta', 'O número máximo de intervalos permitido é 48');
        }
    }

    removeIntervalo(column_id) {
        let data = this.state.list.length > 0 ? this.state.list : this.props.list || [];
        column_id = column_id.split('_');
        let colData = {};
        let colId = 0;
        let colApaga = true;
        let columnId = parseInt(column_id[1]);

        if (parseInt(data[0].numero_intervalo) > 4) {

            for (const idx in data) {
                for (const key in data[idx]) {

                    if (key.indexOf('col_') >= 0) {

                        let validacaoConflitanate = IntervaloValidacao.validaVerdeAmarelo(data, `col_${columnId}`, data[idx].descricao);
                        if (parseInt(validacaoConflitanate) === 0) {
                            colApaga = false;
                        }
                    }
                }
            }

            if (colApaga) {
                for (const idx in data) {
                    colData = {};
                    for (const key in data[idx]) {

                        if (key.indexOf('col_') >= 0) {
                            colId = parseInt(key.split('_')[1]);
                            if (colId === columnId) {

                                // se for igual, continua copiando para validar se está antes ou depois de um P ou A
                                if (data[idx].descricao === 'Mod') {
                                    if ([1, 3].indexOf(parseInt(data[idx][`col_${colId + 1}`])) >= 0) {
                                        colApaga = false;
                                        // colId++;
                                    }
                                    if ([1, 3].indexOf(parseInt(data[idx][`col_${colId - 1}`])) >= 0) {
                                        colApaga = false;
                                    }
                                }

                                if (!colApaga) {
                                    colData[key] = data[idx][key];
                                    if (parseInt(data[0].numero_intervalo) === parseInt(colId)) {// se não pode apagar e se for igual a quantidade de intervalo
                                        toastr.warning('Alerta', 'Não é possível remover um intervalo antes ou depois de um "Principal" ou "Atuado"');
                                    }
                                } else { // se pode apagar e é igual a quantidade de intervalo
                                    if (parseInt(data[0].numero_intervalo) === parseInt(colId)) {
                                        colId--; // isso é necessário pq quando remover o ultimo não entra no colId-- de baixo
                                    }

                                }

                            } else {
                                if (parseInt(colId) < columnId) {// se for menor que o atual, então só copia o existente
                                    colData[key] = data[idx][key];
                                } else {// se for maior que a coluna atual, tem que diminuir 1 em todos as próximas colunas para jogar tudo um para atrás
                                    if (colApaga) {// só vai dimuir e apagar o registro atual se o colApaga for true
                                        colId--;
                                    } else {
                                        toastr.warning('Alerta', 'Não é possível remover um intervalo antes ou depois de um "Principal" ou "Atuado"');
                                    }
                                    colData[`col_${colId}`] = data[idx][key];
                                }
                            }
                        } else {
                            colData[key] = data[idx][key];
                        }
                    }

                    data[idx] = colData;

                }

                data[0].numero_intervalo--;// = colId;

                this.setState({ data });
            } else {
                toastr.warning('Alerta', 'Não é possível remover o intervalo, existem fases conflitantes');
            }
        } else {
            toastr.warning('Alerta', 'O número mínimo de intervalos permitido é 4');
        }
    }

    exibeImagem(iFaseIni, iFaseFin) {
        let listImg = [];
        for (let idx = iFaseIni; idx <= iFaseFin; idx++) {
            listImg.push(<img id={`imgSimulaF${idx}`} src={traffic} height="64" alt="" />);
        }
        return listImg;
    }


    async generateReportProgramacao(iPlano) {
        const html = await generateReport(this.state.dataControlador, this.state.dataGrupo, iPlano);

        var janelaImprimir = window.open('', '', 'width=800,height=600');
        janelaImprimir.document.open();
        janelaImprimir.document.write(html);
        janelaImprimir.document.close();
        janelaImprimir.print();
        janelaImprimir.close();
    }

    async onChangeAnel(event) {
        const selectedOption = event.target.options[event.target.selectedIndex];
        this.setState({ dataAnel: selectedOption.value });
        this.setState({ dataControlador: selectedOption.id });
        await this.props.getList(selectedOption.id, this.state.dataPlano, selectedOption.value).then((result) => {
            this.setState({ list: result.payload.data });
        });
        this.carregaColunas();
        window.$('#titulointervaloplano').html(`Controlador ${this.state.dataNumControlador} - Anel ${selectedOption.value} - Plano ${this.state.dataPlano}`);
    }


    render() {
        const intervalo = this.state.list.length > 0 ? this.state.list : this.props.list || [];
        let iFases = 0;
        if (intervalo.length) {

            let iFaseIni = intervalo[0].faseIni;
            let iFaseFin = intervalo[0].faseFin;

            const columns = this.carregaColunas();
            for (var key in intervalo) {
                if (intervalo[key].descricao[0] === 'F') {
                    iFases += 1;
                }
            }

            intervalo[0].ciclo_tempo_total = IntervaloValidacao.somaCicloTempoTotal(intervalo);

            const required = value => value ? undefined : 'Obrigatório';

            return (

                <div>

                    <Modal
                        show={this.state.show}
                        onHide={this.handleClose}
                        dialogClassName='modal-load'
                        backdrop={false}
                    >

                        <Modal.Body className='modal-body modal-load-body'>
                            <div >
                                <div >
                                    <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                                </div>
                                <div >
                                    <h4><b>Aguarde, processando...</b></h4>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div ref={this.componentRef}>
                        <div className='box-header'>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <div style={{ flex: '1' }}>
                                    <h5 style={{ paddingRight: '30px' }} id='titulointervaloplano' className='box-title'>{`Controlador ${this.state.dataNumControlador} - Anel ${this.state.dataAnel} - Plano ${this.state.dataPlano}`} </h5>
                                    <b>Modo operação:</b> {`${this.state.modOperacaoDesc[intervalo[0].modo_operacao_id]}`}
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column' ,alignItems: 'flex-end'}}>
                                    <ButtonTooltip type='button'  icon='print' label='' data-title='Imprimir Plano' onClick={() => this.generateReportProgramacao(this.state.dataPlano)} />
                                </div>
                            </div>
                        </div>
                        <div className='box box-default'>
                            <div className='box-body'>
                                <Row>
                                    <Grid cols="6">
                                        {this.exibeImagem(iFaseIni, iFaseFin)}
                                    </Grid>
                                    <Grid cols="2">
                                        <div className="btn-toolbar" style={{ marginLeft:'10px', marginBottom:'5px' }} role="toolbar">
                                            <SelectConfAnel value={this.state.dataAnel} onChange={event=>{this.onChangeAnel(event)}}/>
                                            <div className="btn-group" role="group" style={{ marginBottom:'5px' }}>
                                                <ButtonTooltip
                                                    type="button"
                                                    id="btnSimular"
                                                    className="success"
                                                    icon="play"
                                                    label="Simular"
                                                    disabled={this.state.disabledbtnSimula}
                                                    data-title="Simular execução do anel"
                                                    onClick={this.iniciaSimulacao}
                                                />
                                            </div>
                                            {/** PermissãoUsuario para btnTempoReal */}
                                            <PermissaoUsuario tela="CadIntervaloPlano" campo="simular_tempo_real" condicao={true}>
                                                <div className="btn-group" role="group">
                                                    <ButtonTooltip
                                                        type="button"
                                                        id="btnTempoReal"
                                                        className="warning"
                                                        icon="play"
                                                        label="Tempo real do anel"
                                                        disabled={this.state.disabledbtnTempoReal}
                                                        data-title="Execução do anel em tempo real"
                                                        onClick={this.iniciaTempoReal}
                                                    />
                                                </div>
                                            </PermissaoUsuario>
                                        </div>
                                        <div className="grid-row" style={{paddingTop:'10px'}}>
                                            <div className="btn-group" role="group">
                                                <h5 className="tempo_h5"><b>Intervalo</b></h5>
                                                <input id="cronometro" type="text" className="form-control input-cronometro" readOnly={true}/>
                                            </div>
                                            <div className="btn-group" role="group">
                                                <h5 className="tempo_h5"><b>T.T.Ciclo</b></h5>
                                                <input id="totalCiclo" type="text" className="form-control input-total-ciclo" readOnly={true} value={intervalo[0].ciclo_tempo_total}/>
                                            </div>
                                            <div className="btn-group" role="group">
                                                <h5 className="tempo_h5"><b>T.Ciclo</b></h5>
                                                <input id="decorrido" type="text" className="form-control input-decorrido" readOnly={true} value={this.state.tempoDecorrido}/>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid cols="4">
                                        <If condicao={intervalo[0].plano_atu !== ''}>
                                            <Grid cols="6">
                                                <h5 className="mod_operacao_h4"><b>Plano Anterior:</b><br />  {intervalo[0].plano_ant}</h5>
                                                <h5 className="mod_operacao_h4"><b>Plano Atual:</b><br /> {intervalo[0].plano_atu}</h5>
                                                <h5 className="mod_operacao_h4"><b>Próximo Plano:</b><br /> {intervalo[0].plano_pro}</h5>
                                            </Grid>
                                        </If>
                                        <If condicao={this.state.modo !== ''}>
                                            <Grid cols="6">
                                                <h5 className="mod_operacao_h4"><b>Modo Operação:</b><br /> {this.state.modo}</h5>
                                                <h5 className="mod_operacao_h4"><b>Tipo Ciclo:</b><br /> {this.state.tipoCiclo}</h5>
                                                <h5 className="mod_operacao_h4"><b>Defasagem:</b><br /> {this.state.defasagem}</h5>
                                            </Grid>
                                        </If>
                                    </Grid>
                                </Row>
                            </div>
                        </div>
                        {/* <ButtonTooltip type='button' id='btnNovoIntervalo' className='success' icon='add' label='Novo Intervalo' data-title='Novo Intervalo' onClick={this.adicionaIntervalo} /> */}

                        <ReactTableGT2A
                            data={intervalo}
                            columns={columns}
                            defaultPageSize={intervalo.length}
                            showPagination={false}
                            sortable={false}
                        />
                        <SelectPlanoSelect
                            cols='2'
                            id='selIntervaloCopiaPlano'
                            label='Plano para copiar intervalo'
                            data-controlador-id={intervalo[0].controlador_id}
                            disabled={validaPermissaoDisabled('CadIntervaloPlano', 'plano_copiar_intervalo')}
                        />

                        <div className='box-footer'>
                            <PermissaoUsuario tela="CadIntervaloPlano" campo="plano_copiar_intervalo" condicao={true}>
                                <ButtonTooltip type='button' id='btnLerPlano' className='primary botao_copiar_plano' icon='copy' label='Copiar plano' disabled={this.state.disabledbtn} data-title='Copiar intervalo do plano selecionado para o plano atual' onClick={this.copiarPlano} />
                            </PermissaoUsuario>
                            <PermissaoUsuario tela="CadIntervaloPlano" campo="plano_copiar_intervalo" condicao={false}>
                                <ButtonTooltip type='button' id='btnLerPlano' className='primary botao_copiar_plano' icon='copy' label='Copiar plano' disabled='disabled' />
                            </PermissaoUsuario>
                        </div>

                    <div className='col-sm-12' style={{paddingBottom:'10px', paddingLeft:'0px'}}>

                        {/* <If condicao={(['0', '1', '2'].indexOf(intervalo[0].modo_operacao_id) < 0)}> */}
                        <LabelAndInputChange
                            name="ciclo_maximo"
                            type='number'
                            label='Ciclo Máximo'
                            cols='2'
                            // readOnly={(['0','1','2'].indexOf(intervalo[0].modo_operacao_id) < 0 )}
                            value={intervalo[0].ciclo_maximo}
                            // defaultValue={intervalo[0].ciclo_maximo}
                            onChange={this.inputChange}
                            onBlur={this.cicloMaximoOnBlur}
                            readOnly={validaPermissaoReadOnly('CadIntervaloPlano', 'ciclo_maximo_defasagem')}
                        />

                        <LabelAndInputChange
                            name="ciclo_tempo_total"
                            type='number'
                            min="60"
                            label='Ciclo Tempo Total'
                            cols='2'
                            readOnly={true}
                            value={intervalo[0].ciclo_tempo_total}
                        />

                        <LabelAndInputChange
                            name="defasagem"
                            type='number'
                            min="10"
                            label='Defasagem'
                            cols='2'
                            value={intervalo[0].defasagem}
                            onChange={this.inputChange}
                            readOnly={validaPermissaoReadOnly('CadIntervaloPlano', 'ciclo_maximo_defasagem')}
                            validate={required}
                            onBlur={(e) => {
                                const data = this.state.list.length > 0 ? this.state.list : this.props.list || [];
                                let valor = (e.target.value) ? parseInt(e.target.value) : 0;
                                let ciclo_tempo_maximo = data[0].ciclo_tempo_total;
                                if (parseInt(e.target.value) < 0) {
                                    valor = 0;
                                }

                                if (parseInt(e.target.value) >= ciclo_tempo_maximo) {
                                    valor = ciclo_tempo_maximo - 1;
                                }

                                for (var key in data) {
                                    data[key][e.target.name] = valor;
                                    this.setState({ data });
                                }
                            }}
                        />

                        <LabelAndInputChange
                            name="atuado_var"
                            type='number'
                            min="0"
                            label='Headway'
                            cols='2'
                            value={intervalo[0].atuado_var}
                            onChange={this.inputChange}
                            onBlur={(e) => {
                                const data = this.state.list.length > 0 ? this.state.list : this.props.list || [];
                                let valor = (e.target.value) ? parseInt(e.target.value) : 0;
                                if (parseInt(e.target.value) < 0) {
                                    valor = 0;
                                }

                                if (parseInt(e.target.value) > 100) {
                                    valor = 100;
                                }

                                for (var key in data) {
                                    data[key][e.target.name] = valor;
                                    this.setState({ data });
                                }
                            }}
                        />
                    </div>


                        <div className='box-footer'>
                            <PermissaoUsuario tela="CadIntervaloPlano" campo="ler_controlador" condicao={true}>
                                <ButtonTooltip type='button' id='btnLerControlador' className='success' icon='download' label='Ler Controlador' disabled={this.state.disabledbtn} data-title='Busca os dados do controlador' onClick={this.lerPlanoControlador} />
                            </PermissaoUsuario>
                            <PermissaoUsuario tela="CadIntervaloPlano" campo="ler_controlador" condicao={false}>
                                <ButtonTooltip type='button' id='btnLerControlador' className='success' icon='download' label='Ler Controlador' disabled='disabled' />
                            </PermissaoUsuario>


                            <PermissaoUsuario tela="CadIntervaloPlano" campo="ler_sistema" condicao={true}>
                                <ButtonTooltip type='button' id='btnLerPlano' className='primary' icon='file-download' label='Ler do sistema' disabled={this.state.disabledbtn} data-title='Busca os dados do banco de dados do sistema' onClick={this.lerPlanoDB} />
                            </PermissaoUsuario>
                            <PermissaoUsuario tela="CadIntervaloPlano" campo="ler_sistema" condicao={false}>
                                <ButtonTooltip type='button' id='btnLerPlano' className='primary' icon='file-download' label='Ler do sistema' disabled='disabled' />
                            </PermissaoUsuario>

                            <If condicao={!this.state.disabledbtnplano}>
                                <PermissaoUsuario tela="CadIntervaloPlano" campo="ativar_plano_intermitente" condicao={true}>
                                    <ButtonTooltip type='button' id='btnAtivarPlanoIntermitenteControlador' className='success' icon='upload' label='Ativar Plano Intermitente' disabled={this.state.disabledbtn} data-title='Ativar Plano Intermitente(50) nos controladores' onClick={this.ativarPlanoIntermitente} />
                                </PermissaoUsuario>
                                <PermissaoUsuario tela="CadIntervaloPlano" campo="ativar_plano_intermitente" condicao={false}>
                                    <ButtonTooltip type='button' id='btnAtivarPlanoIntermitenteControlador' className='success' icon='upload' label='Ativar Plano Intermitente' disabled='disabled' />
                                </PermissaoUsuario>
                            </If>

                            <If condicao={this.state.disabledbtnplano}>
                                <ButtonTooltip type='button' id='btnDesativarPlanoIntermitenteControlador' className='danger' icon='upload' label='Desativar Plano Intermitente' disabled={this.state.disabledbtn} data-title='Desativar Plano Intermitente(50) nos controladores' onClick={this.desativarPlanoIntermitente} />
                            </If>
                        </div>
                    </div>

                    <div className='box-footer'>
                        <PermissaoUsuario tela="CadIntervaloPlano" condicao={true}>
                            <ButtonTooltip type='button' className="primary" icon='check' label='Salvar/Enviar Controlador' disabled={this.state.disabledbtn} data-title='Salva os dados no banco de dados do sistema' onClick={this.salvaIntervalo} />
                            <ButtonTooltip type='button' className="danger" icon='check' label='Salvar DB' disabled={this.state.disabledbtn} data-title='Salva os dados no banco de dados do sistema' onClick={this.salvaIntervaloDB} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadIntervaloPlano" condicao={false}>
                            <ButtonTooltip type='button' className="primary" icon='check' label='Salvar/Enviar Controlador' disabled='disabled' />
                        </PermissaoUsuario>
                        <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.initPlano} />
                    </div>
                </div>
            )
        }

        return (
            <div></div>
        )

    }
}

const mapStateToProps = state => ({ list: state.intervaloPlanoCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getList, update, initPlano, getListInterval, updateSerial, updatePlano, getControllerStep, sendForced, sendCancelForced }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(IntervaloPlanoList)
