import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { init } from './planoActions';
import ListIntervalo from '../intervalo/intervaloList';

class ControladorFase extends Component {

    render() {
        const { controladorID, planoID, anelID, grupoID, numCont } = this.props;
        if (!controladorID || controladorID.length === 0) {
            return <div>Carregando...</div>;
        }
        return (
            <div>
                <ListIntervalo 
                    data-controlador={controladorID} 
                    data-plano={planoID} 
                    data-anel={anelID}
                    data-grupo={grupoID}
                    data-num-controlador={numCont}
                    ref={el => (this.componentRef = el)}
                />
            </div>
        )
    }
}

const selector = formValueSelector('planoIntervalo');
const mapStateToProps = state => ({
    controladorID: selector(state, 'controlador_id'),
    planoID: selector(state, 'id'),
    anelID: selector(state, 'anel'),
    grupoID: selector(state, 'grupo_fisico_id'),
    numCont: selector(state, 'numero_controlador')
})

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ControladorFase);