import React from 'react';
import FasesConflitantes from './FasesConflitantes';

const corFases = {
  1: {
      cor: '#F44336',
      descricao: 'Vermelho'
    }, 
  2: {
      cor: '#FFEB3B',
      descricao: 'Amarelo'
    },
  3: {
      cor: '#68BC00',
      descricao: 'Verde'
    },
  4: {
      cor: '#b879d2',
      descricao: 'Vermelho Piscante'
     }, 
  5: {
      cor: '#FFFF99',
      descricao: 'Amarelo Piscante'
    },
}

const infoIntervalo = {
  1: {
      cor: '#38B0DE',
      abreviacao: 'P',
      descricao: 'Principal'
     },           
  2: {
      cor: '#70DBDB',
      abreviacao: 'S',
      descricao: 'Secundário'
     },  
  3: {
      cor: '#CD8C95',
      abreviacao: 'A',
      descricao: 'Atuado'
     },  
  4: {
      cor: '#FFB5C5',
      abreviacao: 'SA',
      descricao: 'Sec.Alternativo'
     }, 
  5: {
      cor: '#5F9EA0',
      abreviacao: 'p',
      descricao: 'Principal Variável'
     },  
  6: {
      cor: '#808000',
      abreviacao: 'a',
      descricao: 'Atuado Variável'
     },  
}

const camposIntervalo = {
  Mod: 'Modo',
  TpN: 'Tempo Normal',
  ND: 'Número do Detector',
  TpM: 'Tempo Mínimo',
  SL: 'Sequência Lógica',
  'Av+': 'Tempo de Variável',
  AvM: 'Tempo Mínimo Variável',
  TvE: 'Tempo Extensão',
  TvM: 'Tempo Máximo de Extensão'
}

const corPiscante = {
  4: {
    descricao: 'Vermelho'
  },
  5: {
    descricao: 'Amarelo'
  },
}

const tipoFase = {
  1: {
    descricao: 'Veículo'
  },
  2: {
    descricao: 'Pedestre'
  }
}

function getCorFundoFases(descricao_tempo_minimo) {
  switch (descricao_tempo_minimo) {
    case 'tempo_amarelo_min':
        return '#FFEB3B';
    case 'tempo_verde_min':
        return '#68BC00';
    default:
        return '#f45555';
  }
} 

const HtmlReport = ({ planos, troca_planos, fases, info_fases_conflitantes}) => {
  let sIPControlador = planos[0][0].ip_controlador;
  let iPortaControlador = planos[0][0].porta;
  let iFaseInicial = planos[0][0].faseIni;
  let iFaseFinal = planos[0][0].faseFin;

  let sDescricaoControlador = `Controlador ${planos[0][0].numero_controlador} | IP: ${sIPControlador} | Porta: ${iPortaControlador}`;

  return (
    <div style={{WebkitPrintColorAdjust: 'exact'}}>
      <div className='container-planos'>
      <h2>{sDescricaoControlador}</h2>
      <hr/>

      {planos.map((aPlano,index) => (
          
          <div className={index === 0 ? 'planos-first-div' : 'planos-other-div'} style={{pageBreakAfter: 'always'}}>
            <div>
              <h3>{`Anel Nº ${aPlano[0].numero_anel}: ${aPlano[0].anel_descricao}`}</h3>
              <h3>{`${aPlano[0].plano_descricao} - Modo de Operação: ${aPlano[0].modo_operacao_descricao}`}</h3>
              <br/>

              <table style={{ borderCollapse: 'collapse', width: '40%'}}>
                <tr>
                <th></th>
                {Array.from({ length: aPlano[0].numero_intervalo }, (_, index) => index + 1).map((index) => (
                  <th>{index}</th>        
                ))}
                </tr>

                {aPlano.map((oPlano,linha) => (
                  <tr>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>
                      {oPlano.descricao}
                    </th>

                    {Array.from({ length: aPlano[0].numero_intervalo }, (_, coluna) => coluna + 1).map((coluna) => (
                        
                        linha == 0 ? (
                          <>
                            <td style={{border: '1px solid black', padding: '2px', textAlign: 'center', backgroundColor: infoIntervalo[aPlano[linha][`col_${coluna}`]].cor}}>
                              {infoIntervalo[aPlano[linha][`col_${coluna}`]].abreviacao}
                            </td>
                          </>  
                        ) : (
                          <>
                            <td style={{border: '1px solid black', padding: '2px', textAlign: 'center', backgroundColor: corFases[aPlano[linha][`col_${coluna}`]] !== undefined ? corFases[aPlano[linha][`col_${coluna}`]].cor : 'white'}}>
                              {oPlano.descricao == 'ND' ? '' : aPlano[linha][`col_${coluna}`]}
                            </td>
                          </>
                        ) 
                    ))}
                  </tr>
                ))}
              </table>
            </div>
          </div>
      ))}

      </div>

      {troca_planos.length > 0 && (
        <>
        <div className="container-content" style={{pageBreakAfter: 'always'}}>
          <h1 style={{ textAlign: 'center'}}>Tabela Troca de Planos</h1>

          <div className="tabela-troca-plano"> 
            <table style={{ borderCollapse: 'collapse', width: '40%', justifyContent: 'center'}}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Plano</th>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Domingo</th>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Segunda</th>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Terça</th>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Quarta</th>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Quinta</th>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Sexta</th>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Sábado</th>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Anel</th>
                    <th style={{ textAlign: 'right', paddingRight: '12px'}}>Horário</th>
                  </tr>
                </thead>
          
            {troca_planos.map((trocaPlano,index) => (
              <>
                <tbody>
                  <tr>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.plano_id}</td>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.domingo == 1 ? 'Sim' : 'Não'}</td>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.segunda == 1 ? 'Sim' : 'Não'}</td>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.terca == 1 ? 'Sim' : 'Não'}</td>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.quarta == 1 ? 'Sim' : 'Não'}</td>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.quinta == 1 ? 'Sim' : 'Não'}</td>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.sexta == 1 ? 'Sim' : 'Não'}</td>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.sabado == 1 ? 'Sim' : 'Não'}</td>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.anel}</td>
                    <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{trocaPlano.horario_inicial}</td>
                  </tr>
                </tbody>
              </>
            ))}
            </table>
          </div>

          <br/>
          <hr/>

          {fases.length > 0 && (
          <>
            <h1 style={{ textAlign: 'center'}}>Fases</h1>
            {/* Fases Conflitantes */}
            <FasesConflitantes 
              faseInicial={iFaseInicial}
              faseFinal={iFaseFinal}
              info_fases_conflitantes={info_fases_conflitantes}
            />
            <br/>

            <table style={{ borderCollapse: 'collapse', width: '40%', justifyContent: 'center'}}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Fase</th>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Monitora Vermelho</th>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Cor Piscante</th>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Latitude</th>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Longitude</th>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Tipo</th>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Quantidade</th>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Tempo mínimo</th>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Tempo mínimo</th>
                      <th style={{ textAlign: 'right', paddingRight: '12px'}}>Tempo mínimo</th>
                    </tr>
                  </thead>
            
              {fases.map((fase,index) => (
                <>
                  <tbody>
                    <tr>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{fase.id}</td>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{fase.ausencia_vermelho == 1 ? 'Sim' : 'Não'}</td>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{corPiscante[fase.cor_piscante].descricao}</td>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{fase.latitude}</td>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{fase.longitude}</td>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{tipoFase[fase.tipo].descricao}</td>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center'}}>{fase.quantidade}</td>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center', backgroundColor: getCorFundoFases('tempo_vermelho_min')}}>{fase.tempo_vermelho_min}</td>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center', backgroundColor: getCorFundoFases('tempo_amarelo_min')}}>{fase.tempo_amarelo_min}</td>
                      <td style={{border: '1px solid black', padding: '2px', textAlign: 'center', backgroundColor: getCorFundoFases('tempo_verde_min')}}>{fase.tempo_verde_min}</td>
                    </tr>
                  </tbody>
                </>
              ))}
            </table>      
          </>
          )} 
      </div>
      </>
      )} 

    <br/>
    <hr/>
      
    <div className="container-content">
      <h1 style={{ textAlign: 'center'}}>Legendas</h1>
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', gap: '20px'}}>
          <div style={{flex: 1, maxWidth: '45%', textAlign: 'center'}}>
            <h3>Fases</h3>
            <table style={{ borderCollapse: 'collapse', margin: 'left', width: '100%'}}>
              <tbody>
                {Object.keys(corFases).map(key => (
                  <tr key={key}>
                    <td style={{ border: '1px solid black', backgroundColor: corFases[key].cor, textAlign: 'center', width: '30%'}}>{key}</td>
                    <td style={{ border: '1px solid black', textAlign: 'left', paddingLeft: '3px', width: '70%'}}>{corFases[key].descricao}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        <div style={{flex: 1, maxWidth: '45%', textAlign: 'center'}}>
          <h3>Intervalos</h3>
          <table style={{ borderCollapse: 'collapse', margin: 'left', width: '100%'}}>
            <tbody>
              {Object.keys(infoIntervalo).map(key => (
                <tr key={key}>
                  <td style={{ border: '1px solid black', backgroundColor: infoIntervalo[key].cor, textAlign: 'center', width: '30%'}}>{infoIntervalo[key].abreviacao}</td>
                  <td style={{ border: '1px solid black', textAlign: 'left', paddingLeft: '3px', width: '70%'}}>{infoIntervalo[key].descricao}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={{flex: 1, maxWidth: '45%', textAlign: 'center'}}>
          <h3>Campos Intervalo</h3>
          <table style={{ borderCollapse: 'collapse', margin: 'left', width: '100%'}}>
            <tbody>
              {Object.keys(camposIntervalo).map(key => (
                <tr key={key}>
                  <td style={{ border: '1px solid black', textAlign: 'center', width: '30%'}}>{key}</td>
                  <td style={{ border: '1px solid black', textAlign: 'left', paddingLeft: '3px', width: '70%'}}>{camposIntervalo[key]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
  </div>
  </div>
)};

export default HtmlReport;