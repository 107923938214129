import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../auth/authActions';
import './custom.css';
import ModalHeader from '../modal/modal';
import ModalSeleciona from '../modal/modalSeleciona';
import { Redirect } from 'react-router-dom'
import { toastr } from 'react-redux-toastr';
import { getAlertaErro, clearAlertaErro } from '../../controlador_erro/erroActions.js';
import swal from 'sweetalert2';
import moment from 'moment';
import sound from '../sound/alert.mp3';
import manual from '../artifact/Manual_Central_Online_SSAT.pdf';
import axiosInstance from '../../main/axios.js';

import UserLocal from '../../configuracao/userLocal';
import NotificationModal from './notificationModal';

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.intervalAlertaErroGeral = 0;
        this.intervalAudioErroGeral = 0;

        this.state = {
            open: false,
            show: false,
            body: null,
            auxGrup: null,
            auxCont: null,
            auxGrupD: '',
            auxContD: '',
            auxNumCont: '',
            Grup: 'Selecionar',
            Cont: 'Selecionar',
            GrupD: 'Selecionar Grupo Físico',
            ContD: 'Selecionar Controlador',
            numCont: null,
            contLat: null,
            contLong: null,
            anel: 1,
            redirecionarControlador: '',
            loop: 0,
            redirect: false,
            erros: [],
            notifications: [],
            quantidadeNotifications: 0,
            modalNotification: false,
        }

        this.clickOk = this.clickOk.bind(this);
        this.modalSelecionaShow = this.modalSelecionaShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.changeControlador = this.changeControlador.bind(this);
        this.changeGrupoFisico = this.changeGrupoFisico.bind(this);
        this.validaSelecao = this.validaSelecao.bind(this);
        this.validaSelecaoOK = this.validaSelecaoOK.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalNotification !== prevState.modalNotification) {
          if (this.state.modalNotification) {
            document.body.classList.add('modal-open');
          } else {
            document.body.classList.remove('modal-open');
          }
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalAlertaErroGeral);
        clearInterval(this.intervalAudioErroGeral);
        document.body.classList.remove('modal-open');
    }

    componentDidMount() {
        const styleSheet = document.styleSheets[0];

        const keyframes =
        `@keyframes shake {
            0%, 100% { transform: translateX(0); }
            10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
            20%, 40%, 60%, 80% { transform: translateX(5px); }
        }`;
    
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

        this.intervalAlertaErroGeral = setInterval(() => {
            try {
                if (this.state.loop === 0) {
                    this.setState({ loop: 1 });
                    // console.log('ESPERANDO ERRO');
                    
                    axiosInstance.get(`/controladoreserrotelegram`);
                    
                    this.props.getAlertaErro()
                        .catch((e) => {
                            this.setState({ loop: 0 });
                        })
                        .then(async (result) => {
                            let alerta = this.state.erros.concat(result.payload.data);
                            
                            if (alerta.length > 0) {
                                this.setState({ erros: alerta });

                                if (UserLocal.getUserConfig('alerta_tela') == 1) {
                                    await this.showMessage();
                                } else {
                                    this.trataNotificacoes();
                                }
                            }
                            this.setState({ loop: 0 });
                        });
                }
            } catch (error) {
                this.setState({ loop: 0 });
            }
            // }, 10000);
            // }, 60000);
        }, 10000);
    }

    showMessage() {
        const alerta  = this.state.erros;
        let msgHtml = '';
        if (alerta.length > 0) {
                
            for (const key in alerta) {
                const linha = alerta[key];
                let dataFormat = moment(linha.data_erro).format('DD/MM/YYYY');
                let dataKey = moment(linha.data_erro).format('YYYYMMDD');
                let identificador = `${linha.controlador_id + dataKey + linha.hora_erro}`;
                let exp = new RegExp(identificador);

                if (!exp.test(msgHtml)) {
                    msgHtml += `<div key="${identificador}"><h4><b>Grupo:</b> ${linha.grupo_fisico_id} <b>Cont:</b> ${linha.numero_controlador} <b>Msg:</b> [${dataFormat} ${linha.hora_erro}] ${linha.mensagem}</h4></div>`;
                } else  {
                    alerta.splice(key, 1);
                }
            }

            // gera barra de rolagem caso tenha mais de 5 erros
            if (alerta.length > 5) {
                msgHtml = '<div style="width: 500px; height: 300px; overflow-y: scroll;">' + msgHtml + '</div>';
            }

            (new Audio(sound)).play();
            clearInterval(this.intervalAudioErroGeral);
            this.intervalAudioErroGeral = setInterval(() => {
                (new Audio(sound)).play();
            }, 5000);
            
            return swal({
                title: '<h3 style="color:red">Controladores com erro</h3>',
                html: msgHtml,
                type: 'error',
                width: '520px',
                heightAuto: false,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                cancelButtonColor: '#f39c12',
                cancelButtonText: 'Log de Erros',
                allowOutsideClick: false, // obriga a clicar em um botão se tiver false, se não ele permite clicar fora da mensagem
                timer: 30000,
            }).then((result) => {
                clearInterval(this.intervalAudioErroGeral);
                if (result.dismiss == 'timer') {
                    this.showMessage();
                } else {
                    this.props.clearAlertaErro(alerta);
                    this.setState({ erros: [] });
                    this.setState({ notifications: []})
                    this.setState({quantidadeNotifications: 0})
                }
            });
        }
    }

    trataNotificacoes() {
        const alertas  = this.state.erros;
        
        if (alertas.length > 0) {
            let iQtdNotificacoesNovas = 0;
            
            for (const idAlerta in alertas) {
                let alerta = alertas[idAlerta],
                    iControlador = alerta.controlador_id,
                    dataAlerta = new Date(alerta.data_erro),
                    sDateFormatted = dataAlerta.toISOString().split('T')[0],
                    sHoraErro = alerta.hora_erro,
                    sKeyAlerta = `${iControlador}_${sDateFormatted}_${sHoraErro}`;

                    if(!this.state.notifications[sKeyAlerta]) {
                      // Cria uma cópia do estado atual das notificações
                        let updatedNotifications = { ...this.state.notifications };

                        // Adiciona a nova notificação com a chave gerada
                        updatedNotifications[sKeyAlerta] = alerta;

                        // Atualiza o estado com as notificações atualizadas
                        this.setState({ notifications: updatedNotifications });

                        iQtdNotificacoesNovas++;
                    }             
            }

            if (iQtdNotificacoesNovas > 0) {
                if (iQtdNotificacoesNovas == 1) {
                    toastr.info('Atenção', `Você possui uma notificação nova!`, {
                        position: 'bottom-right'
                    });
                } else {
                    // toastr.options.positionClass = 'toast-bottom-right';
                    toastr.info('Atenção', `Você possui ${iQtdNotificacoesNovas} notificações novas!`, {
                        position: 'bottom-right'
                    });
                }
            }

            this.setQuantidadeNotifications(alertas.length);
            this.setState({ erros: [] });
        }
    }

    setQuantidadeNotifications(iQtd) {
        this.setState({quantidadeNotifications: iQtd})
    }

    clickOpen() {
        this.setState({ open: !this.state.open })
    }

    changeClose() {
        this.setState({ open: false })
    }

    handleClose() {
        if (this.validaSelecao()) {
            this.setState({ show: false });
        }
    }

    changeGrupoFisico(event) {
        let index = event.nativeEvent.target.selectedIndex;

        this.setState({ auxGrup: event.target.value });
        this.setState({ auxGrupD: event.nativeEvent.target[index].text });

        this.setState({ auxCont: null });
        this.setState({ auxContD: 'Selecionar' });
        this.setState({ auxNumCont: 'Selecionar' });

    }

    changeControlador(event, latLon, anel) {

        if (event.target.value !== 'Selecionar') {
            // let index = event.nativeEvent.target.selectedIndex;
            // let contDescricao = event.nativeEvent.target[index].text;
            let index = event.target.selectedIndex;
            let contDescricao = event.target[index].text;
            let numeroCont = contDescricao.split('-');
            let latLonSplit = latLon.split('|');

            this.setState({ auxCont: event.target.value });
            this.setState({ auxContD: event.nativeEvent.target[index].text });
            this.setState({ auxNumCont: parseInt(numeroCont) });
            this.setState({ contLat: latLonSplit[0] });
            this.setState({ contLong: latLonSplit[1] });
            this.setState({ anel: anel });
        }
    }

    modalSelecionaShow() {

        var _cfg = localStorage.getItem('_cfg');

        if (_cfg != null) {
            _cfg = JSON.parse(_cfg);

            this.setState({ Grup: _cfg.grupo });
            this.setState({ Cont: _cfg.cont });
            this.setState({ GrupD: _cfg.grupoDes });
            this.setState({ ContD: _cfg.contDes });

            this.setState({ auxGrup: this.state.Grup });
            this.setState({ auxCont: this.state.Cont });
            this.setState({ auxGrupD: this.state.GrupD });
            this.setState({ auxContD: this.state.ContD });
        }

        this.setState({
            body:
                <div>
                    <ModalSeleciona
                        grupoVal={this.state.Grup}
                        controladorVal={this.state.Cont}
                        onChangeGrupoFisico={this.changeGrupoFisico}
                        onChangeControlador={this.changeControlador}
                    />
                    {/* <input id="navbarSelecionaLatLon" type="hidden" value="teste" /> */}
                </div>
        });

        this.setState({ show: true });

    }

    validaSelecao() {
        let grupochildCount = window.$('#seleciona_grupo_fisico')[0].childElementCount;
        let contchildCount = window.$('#seleciona_controlador')[0].childElementCount;

        if ((grupochildCount > 1) && ((this.state.Grup == null) || (this.state.Grup === 'Selecionar'))) {
            if ((this.state.auxGrup === 'Selecionar') || this.state.auxGrup == null) {
                toastr.warning('Alerta', 'Selecione um "Grupo Físico".');
                return false;
            }
        }
        if ((contchildCount > 1) && (this.state.Cont === 'Selecionar')) {
            if ((this.state.auxCont === 'Selecionar') || (this.state.auxCont == null)) {
                toastr.warning('Alerta', 'Selecione um "Controlador".');
                return false;
            }
        }

        return true;
    }

    validaSelecaoOK() {
        if (this.validaSelecao()) {

            if ((this.state.auxGrup === 'Selecionar') || this.state.auxGrup == null) {
                toastr.warning('Alerta', 'Selecione um "Grupo Físico".');
                return false;
            }

            if ((this.state.auxCont === 'Selecionar') || (this.state.auxCont == null)) {
                let contchildCount = window.$('#seleciona_controlador')[0].childElementCount;
                if (contchildCount > 1) {
                    toastr.warning('Alerta', 'Selecione um "Controlador".');
                    return false;
                }
            }

            return true;
        }
        return false;
    }

    clickOk(history) {

        let _cfg = {};
        let _confAtual = localStorage.getItem('_cfg');
        // let grupo_text = window.$('#selGrupoFisicoNavBar span').text();
        _confAtual = JSON.parse(_confAtual);

        if (this.validaSelecaoOK()) {

            if ((this.state.auxCont === null) && (this.state.auxGrup != this.state.Grup)) {
                // console.log('É necessário selecionar um grupo e um controlador');

                _cfg = {
                    grupo: this.state.auxGrup,
                    grupoDes: this.state.auxGrupD,
                    cont: 'Selecionar',
                    contDes: 'Selecionar Controlador',
                    numCont: null,
                    contLat: this.state.contLat,
                    contLong: this.state.contLong,
                    anel: this.state.anel,
                    event: 0
                };

                localStorage.setItem('_cfg', JSON.stringify(_cfg));

                this.setState({ Cont: 'Selecionar' });
                this.setState({ ContD: 'Selecionar Controlador' });
                this.setState({ numCont: null });

                this.setState({ Grup: this.state.auxGrup });
                this.setState({ GrupD: this.state.auxGrupD });

                this.setState({ show: false });
                this.setState({ redirect: true });
                // window.location = "/#/cadcontrolador";
                // window.location = "/";

            } else {

                if (this.state.auxCont === null) {
                    _cfg = {
                        grupo: this.state.auxGrup,
                        grupoDes: this.state.auxGrupD,
                        cont: this.state.Cont,
                        contDes: this.state.ContD,
                        numCont: this.state.numCont,
                        contLat: this.state.contLat,
                        contLong: this.state.contLong,
                        anel: this.state.anel,
                        event: 0
                    };

                } else {

                    let descCont = this.state.auxContD;

                    if (_confAtual) {

                        // descCont = (_confAtual.cont === null) ? _confAtual.contDes : this.state.auxContD;
                        // descCont = (_confAtual.cont === '') ? _confAtual.contDes : this.state.auxContD;
                        // descCont = this.state.auxContD; 
                        // if (_confAtual.cont === null){
                        //     descCont = _confAtual.contDes;
                        // } else {
                        //     if (_confAtual.cont !== ''){
                        //         descCont = _confAtual.contDes;
                        //     }
                        // }

                        descCont = (_confAtual.event === 2) ? _confAtual.contDes : this.state.auxContD;
                        descCont = ((_confAtual.event === 1) && (parseInt(_confAtual.cont) === parseInt(this.state.auxCont))) ? _confAtual.contDes : descCont;
                    }

                    _cfg = {
                        grupo: this.state.auxGrup,
                        grupoDes: this.state.auxGrupD,//grupo_text,
                        cont: this.state.auxCont,
                        contDes: descCont,
                        numCont: this.state.auxNumCont,
                        contLat: this.state.contLat,
                        contLong: this.state.contLong,
                        anel: this.state.anel,
                        event: 0
                    };

                    this.setState({ Cont: this.state.auxCont });
                    this.setState({ ContD: descCont });
                    this.setState({ numCont: this.state.auxNumCont });
                }

                localStorage.setItem('_cfg', JSON.stringify(_cfg));

                this.setState({ Grup: this.state.auxGrup });
                this.setState({ GrupD: this.state.auxGrupD });
                // this.setState({ GrupD: grupo_text });
                // this.setState({ auxGrupD: grupo_text });

                this.setState({ show: false });
                // recarrega a tela de dashboard, ou vai para a tela de dashboard
                // window.location = "/";
                this.setState({ redirect: true });
            }

        }
    }

    render() {
        if (this.state.redirect === true) {
            this.setState({ redirect: false });
            return <Redirect to='/cadcontrolador' />
        }

        const { name, email } = this.props.user;

        let nomeIniciais = name.split(' ');
        if (nomeIniciais.length > 1) {
            nomeIniciais = `${nomeIniciais[0][0]}${nomeIniciais[1][0]}`;
        } else {
            nomeIniciais = `${nomeIniciais[0][0]}${nomeIniciais[0][1]}`;
        }

        const handleOpenModal = (e) => {
            e.preventDefault();
            this.setState({ modalNotification: true })
        };

        const handleCloseModal = () => {
            this.setState({ modalNotification: false })
        };

        const clearAlertaErro = async (notification, bDisparaMensagemSucesso) => {
            const alerts = new Array(notification);
            const response = await this.props.clearAlertaErro(alerts);

            if (response.payload.status == 200) {
                let iControlador = notification.controlador_id,
                dataAlerta = new Date(notification.data_erro),
                sDateFormatted = dataAlerta.toISOString().split('T')[0],
                sHoraErro = notification.hora_erro,
                sKeyAlerta = `${iControlador}_${sDateFormatted}_${sHoraErro}`;

                let updatedNotifications = { ...this.state.notifications };

                delete updatedNotifications[sKeyAlerta] ;

                this.setState({ notifications: updatedNotifications });

                this.setQuantidadeNotifications(this.state.quantidadeNotifications-1);

                if (bDisparaMensagemSucesso) {
                    toastr.success('Sucesso', 'A mensagem foi marcada como Lida.', {
                        position: 'bottom-right'
                    });
                }
            }
        }

        const handleTodasMensagensLidas = async () => {
            Object.entries(this.state.notifications).forEach(([chave, notification]) => {
                clearAlertaErro(notification);
            });

            toastr.success('Sucesso', 'Todas as notificações foram lidas.', {
                position: 'bottom-right'
            });
        }

        return (
            <div className="navbar-custom-menu">
                <ul className="nav navbar-nav">
                <li className={`dropdown user user-menu`}>
                    <a href="#" id="notifications" className="dropdown-toggle a_button" data-toggle="modal" onClick={handleOpenModal}>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                        <i 
            className="fa fa-bell" 
            style={{
                animation: this.state.quantidadeNotifications > 0 ? 'shake 1.5s ease-in-out infinite' : 'none'
            }}
        ></i>
                            {this.state.quantidadeNotifications > 0 && (
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '-10px',
                                        right: '-10px',
                                        backgroundColor: 'red',
                                        color: 'white',
                                        borderRadius: '50%',
                                        padding: '2px 6px',
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {this.state.quantidadeNotifications}
                                </span>
                            )}
                        </div>
                    </a>
                </li>

                    
                    <NotificationModal
                        showModal={this.state.modalNotification}
                        handleCloseModal={handleCloseModal}
                        notifications={this.state.notifications}
                        clearAlertaErro={clearAlertaErro}
                        handleTodasMensagensLidas={handleTodasMensagensLidas}
                    />
                
                    <li className={`dropdown user user-menu`}>
                        <a href={manual}
                            id='downloadManual'
                            target='_blank'
                            rel="noopener noreferrer"
                            className="dropdown-toggle a_button"
                            aria-expanded={this.state.open ? 'true' : 'false'}
                        >
                            <i className={'fa fa-download'}></i>
                            <span className="hidden-xs">Manual</span> <br />
                        </a>
                    </li>

                    <li className={`dropdown user user-menu`}
                        style={{'display': 'none'}}
                    >
                        <a href
                            id='selGrupoFisicoNavBar'
                            className="dropdown-toggle a_button"
                            onClick={this.modalSelecionaShow}
                            aria-expanded={this.state.open ? 'true' : 'false'}
                        >
                            <i className={'fa fa-server'}></i>
                            <span className="hidden-xs">{this.state.GrupD}</span> <br />
                        </a>
                    </li>

                    <li className={`dropdown user user-menu`}
                        style={{'display': 'none'}}
                    >
                        <a href
                            id='selControladorNavBar'
                            className="dropdown-toggle a_button"
                            onClick={this.modalSelecionaShow}
                            aria-expanded={this.state.open ? 'true' : 'false'}
                        >
                            <i className={'fa fa-hdd'}></i>
                            <span className="hidden-xs">{this.state.ContD}</span> <br />
                        </a>
                    </li>

                    <li onMouseLeave={() => this.changeClose()}
                        className={`dropdown user user-menu ${this.state.open ? 'open' :
                            ''}`}
                    >
                        <a href
                            onMouseEnter={() => this.clickOpen()}
                            aria-expanded={this.state.open ? 'true' : 'false'}
                            className="dropdown-toggle a_button"
                            data-toggle="dropdown">
                            <i className={'fa fa-user'}></i>
                            <i className="fa fa-caret-down pull-right"></i>
                            <span className="hidden-xs">{nomeIniciais}</span>
                        </a>
                        <ul className="dropdown-menu dropdown_menu">
                            <li className="user-header">
                                <p>{name}<small>{email}</small></p>
                            </li>
                            <li className="user-footer">
                                {/* <div className="pull-left">
                                    <a href="#" onClick={this.props.logout} className="btn btn-default btn-sm">
                                        <i className={'fa fa-gear'}></i>
                                        <span className="hidden-xs"> Configuração</span>
                                    </a>
                                </div> */}
                                <div className="pull-right">
                                    <button onClick={this.props.logout} className="btn btn-default btn-sm">
                                        <i className={'fa fa-sign-out-alt'}></i>
                                        <span className="hidden-xs"> Sair</span>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>

                {this.state.redirecionarControlador}

                <ModalHeader
                    title='Selecionar'
                    show={this.state.show}
                    handleClose={this.handleClose}
                    clickOk={this.clickOk}
                >
                    {this.state.body}
                </ModalHeader>
            </div>
        )
    }
}

const mapStateToProps = state => ({ user: state.auth.user })
const mapDispatchToProps = dispatch => bindActionCreators({ logout, getAlertaErro, clearAlertaErro }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Navbar)