require('dotenv').config();

console.log(process.env);
// console.log(process.env.API_URL);

export default {
    // API local
    // API_URL: 'http://localhost:14401/api',
    // OAPI_URL: 'http://localhost:14401/oapi',

    // API de teste
    API_URL: 'https://teste.centralssat.com.br:14401/api',
    OAPI_URL: 'https://teste.centralssat.com.br:14401/oapi',  
    
    // API de produção
    // API_URL: 'https://centralssat.com.br:14801/api',
    // OAPI_URL: 'https://centralssat.com.br:14801/oapi',

    SERVER_IP: 'localhost',// cria um client tcp na maquina local(NÃO É USADO, ERA SÓ TESTE)
    // SERVER_IP: '192.168.1.19',
    SERVER_PORTA: 5000,// porta utilizada para conectar no server de comunicação(NÃO É USADO, ERA SÓ TESTE)
    NUM_INTERVALO: 48,
    NUM_FASE: 16,
    LINK_CHAT_TELEGRAM: 'https://t.me/Teste_SSAT_bot'
}