import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { getNotificacoesUsuario, changeNotificacao, init } from './notificacoesUsuarioActions';
import LabelAndInput from '../common/form/labelAndInput';
import Button from '../common/form/button';
import CheckboxNotif from '../common/form/checkboxNotif';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';

class UsuarioNotificacoesForm extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            filtrar_notificacao: this.props.filtrar_notificacao,
            alerta_tela: this.props.alerta_tela,
            notificacoes: [],
            modificado: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.notificacoes.length === 0 && nextProps.notificacoes.length > 0 && !prevState.modificado) {
            let notificacaoesSelecionadasIds = nextProps.notificacoes.filter(notificacao => notificacao.selecionado > 0).map(notificacao => notificacao.id);
            if (notificacaoesSelecionadasIds.length === 0) {
                notificacaoesSelecionadasIds = nextProps.notificacoes.map(notificacao => notificacao.id);
            }
            return {
                notificacoes: notificacaoesSelecionadasIds
            };
        }
    }

    handleOnChange = (event) => {
        const notificacoes = this.state.notificacoes;
        const id = parseInt(event.target.id);
        this.setState({ modificado: true });
        if (!notificacoes.includes(id)) {
            notificacoes.push(id);
        } else {
            notificacoes.splice(notificacoes.indexOf(id), 1);
        }
        this.setState({ notificacoes });
    }

    handleOnChangeFiltrar = (event) => {
        this.setState({ filtrar_notificacao: parseFloat(event.target.value) });
    }

    handleOnChangeAlertaTela = (event) => {
        this.setState({ alerta_tela: parseFloat(event.target.value) });
    }

    handleOnSubmit = (event) => {
        event.preventDefault();
        const selecionouTodas = this.state.notificacoes.length === this.props.notificacoes.length;
        this.props.changeNotificacao({
            id: this.state.id,
            filtrar_notificacao: this.state.filtrar_notificacao,
            alerta_tela: this.state.alerta_tela,
            notificacoes: selecionouTodas ? [] : this.state.notificacoes
        });
    }

    render() {
        const notificacoes = this.props.notificacoes || [];
        const naoPossuiNotificacoesSelecionadas = notificacoes.filter(notificacao => notificacao.selecionado > 0).length === 0;
        const filtrarTooltip = (
            <>
                <span style={{marginRight: "3px"}}>Filtrar erros na listagem</span>
                <OverlayTrigger 
                    placement="top" 
                    delayShow={300} 
                    delayHide={150} 
                    overlay={<Tooltip id="tooltip">Ao ativar, assim como as notificações de erro, as listagens de erros também serão afetadas, mostrando apenas os erros selecionados</Tooltip>}
                >
                    <i className={`fa fa-question-circle`} />
                </OverlayTrigger>
            </>
          );

        const alertaEmTelaTooltip = (
            <>
                <span style={{marginRight: "3px"}}>Exibir alertas em tela</span>
                <OverlayTrigger 
                    placement="top" 
                    delayShow={300} 
                    delayHide={150} 
                    overlay={<Tooltip id="tooltip">Ao ativar, as listagens de erros deverão ser exibidas em tela.</Tooltip>}
                >
                    <i className={`fa fa-question-circle`} />
                </OverlayTrigger>
            </>
        );
        
        return (
            <form onSubmit={this.handleOnSubmit}>
                <div className='box-body'>
                    <Field name='nome' component={LabelAndInput} readOnly={true}
                        label='Nome' cols='12' placeholder='Automático' />

                    <Field name='filtrar_notificacao' component={LabelAndSelectForm} label={filtrarTooltip} onChange={this.handleOnChangeFiltrar}>
                        <option value='1'>Sim</option>
                        <option value='0'>Não</option>
                    </Field>

                    <Field name='alerta_tela' component={LabelAndSelectForm} label={alertaEmTelaTooltip} onChange={this.handleOnChangeAlertaTela}>
                        <option value='1'>Sim</option>
                        <option value='0'>Não</option>
                    </Field>
                    
                    <div className='form-group' ref={this.checkboxRef}>
                        <div style={{ marginLeft: 15, marginTop: 15 }}>
                            <label className='control-label'>Notificações a serem mostradas</label>
                            <p>
                                <small>
                                    Selecione as notificações que deseja que sejam mostradas para este usuário. Por padrão, todas as notificações são mostradas.
                                </small>
                            </p>
                        </div>
                        {notificacoes && notificacoes.map((notificacao, index) => {
                            return (
                                <CheckboxNotif 
                                    cols='3'
                                    key={index} 
                                    id={notificacao.id}
                                    component={CheckboxNotif} 
                                    label={notificacao.descricao}
                                    onChange={this.handleOnChange}
                                    value={notificacao.id}
                                    defaultChecked={!naoPossuiNotificacoesSelecionadas ? notificacao.selecionado > 0 : true}
                                    type='checkbox'
                                />
                            )
                        })}
                    </div>

                </div>
                <div className='box-footer '>
                    <Button type='submit' className={this.props.submitClass} icon='check' label={this.props.submitLabel} />
                    <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                </div>
            </form>
        )
    }
}


UsuarioNotificacoesForm = reduxForm({ form: 'usuarioNotificacoesForm', destroyOnUnmount: true })(UsuarioNotificacoesForm);
const selector = formValueSelector('usuarioNotificacoesForm');
const mapDispatchToProps = dispatch => bindActionCreators({ init, getNotificacoesUsuario, changeNotificacao }, dispatch);
export default connect(
    state => ({
        id: selector(state, 'id'),
        filtrar_notificacao: selector(state, 'filtrar_notificacao'),
        alerta_tela: selector(state, 'alerta_tela'),
        notificacoes: state.notificacoesList.list,
    }),
    mapDispatchToProps
)(UsuarioNotificacoesForm
)
