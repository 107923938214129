import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LabelAndSelectChange from '../form/labelAndSelectChange';

import { getGrupoFisicoList } from './selectdbAtions';

class SelectAllGrupoFisico extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }
    }

    componentWillMount() {
        this.props.getGrupoFisicoList();
    }

    renderOptions() {
        const list = this.props.list || [];

        if (list.length === 0) {
            return <option value=''>Carregando...</option>
        }
        
        return list.map(grupo => (
            <option key={grupo.id} value={grupo.id}>
                {grupo.id} - {grupo.descricao}
            </option>
        ))
    }

    render() {

        return (

            <LabelAndSelectChange
                id={this.props.id}
                name={this.props.name}
                label={this.props.label}
                cols={this.props.cols}
                onChange={(event) => {
                    this.setState({ value: event.target.value });
                    this.props.onChange(event);
                }}
                value={this.state.value}
            >
                <option value="0">Selecione...</option>
                {this.renderOptions()}
            </LabelAndSelectChange>

        )
    }
}

const mapStateToProps = state => ({ list: state.grupoFisicoCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getGrupoFisicoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectAllGrupoFisico)