import axiosInstance from '../main/axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import { initialize } from 'redux-form'

const INITIAL_VALUES = {
    grupoFisicoId : 0,
    contId : 0,
    pf_anel: 1
}

export function sendForced(values) {
    const request = axiosInstance.post(`/planoforcado/sendforced`, values);

    return {
        type: '',
        payload: request
    }
}

export function sendCancelForced(values) {
    const request = axiosInstance.delete(`/planoforcado/sendforced/${values.grupoFisicoId}/${values.contId}/${values.pf_anel}`);

    return {
        type: '',
        payload: request
    }
}

export function init() {
    return [
        showTabs('tabExec'),
        selectTab('tabExec'),
        initialize('planoForcadoForm', INITIAL_VALUES)
    ]
}

//initialize('planoForm', INITIAL_VALUES)